import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import { GetFontFamily } from "./common/Helpers";
// create default theme to get breakpoints
const theme = createTheme();
const colorCard = {
    primary: "#000000",
    background: "#FFFFFF",
    cardBackground: "#FFFFFF",
    tableBackground: "#FFFFFF",
    background2: "#F2F2F2",
    accordionClosed: "#F2F2F2",
    tableOutline: "#D9D9D9",
    dividerLine: "#D9D9D9",
    secondaryHover: "#D9D9D9",
    background3: "#B3B3B3",
    wcagOutline: "#808080",
    inputOutline: "#808080",
    placeholderText: "#808080",
    wcag: "#666666",
    gdprText: "#666666",
    helperText: "#666666",
    primaryHover: "#666666",
    tetriaryHover: "#666666",
    subhead: "#333333",
    bodyText: "#333333",
    headlines: "#000000",
    label: "#000000",
    inputText: "#000000",
    success: "#029640",
    successBackground: "#D6EEE0",
    info: "#0082D6",
    infoBackground: "#D6EBF9",
    warning: "#FFD100",
    warningBackground: "#FFF8D6",
    error: "#EB0D3F",
    errorBackground: "#FBD6DD",
};
// More properties here: https://material-ui.com/customization/default-theme/
export const audi = {
    themeName: "audi",
    colorCard: colorCard,
    brand: BrandsEnum.Audi,
    shape: {
        borderRadius: 0,
    },
    USPContainerBlock: {
        backgroundColor: colorCard.primary,
        textColor: colorCard.background2,
        headerColor: colorCard.background,
    },
    customStyling: {
        global: {
            textArea: {
                legalText: {
                    color: colorCard.gdprText,
                },
            },
            tetriaryButton: {
                textDecoration: "underline",
            },
            tetriaryLink: {
                textDecoration: "underline",
            },
            secondaryButton: {
                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
            },
        },
        USPBlock: {
            header: {
                color: colorCard.background,
                paddingBottom: 12,
                fontSize: "1.5rem",
                [theme.breakpoints.down("xs")]: {
                    fontSize: "1.25rem",
                },
            },
            text: {
                color: colorCard.background2,
                fontSize: "1rem",
            },
        },
        AccordionBlock: {
            accordionBox: {
                borderTop: `1px solid ${colorCard.accordionClosed}`,
                borderBottom: `1px solid ${colorCard.accordionClosed}`,
            },
        },
        AccordionBlockContainer: {
            root: {
                border: `1px solid ${colorCard.dividerLine}`,
            },
        },
        ArticleTeaser: {
            root: {
                border: `1px solid ${colorCard?.dividerLine}`,
            },
            icon: {
                backgroundPosition: "bottom left",
                textIndent: "30px",
            },
        },
        CardInformationBlock: {
            headerwrapper: {
                backgroundColor: colorCard.bodyText,
            },
            cardLinkInversed: {
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background2,
                },
            },
            teaserGridInversed: {
                backgroundColor: colorCard.bodyText,
            },
        },
        CustomizedTextBox: {
            textBoxWrapper: {
                background: colorCard.background2,
            },
        },
        SelectionElementBlock: {
            selectBox: {
                background: colorCard.background2,
            },
        },
        CustomizedTextBoxTooltip: {
            tooltipIcon: {
                width: 24,
                height: 24,
            },
        },
        Footer: {
            aboutFooterText: {
                fontSize: "0.875rem",
                color: colorCard.background,
            },
            wrapper: {
                borderTop: `1px solid ${colorCard?.tetriaryHover}`,
                borderBottom: `1px solid ${colorCard?.tetriaryHover}`,
                backgroundColor: colorCard.primary,
                "& .MuiTypography-h4": {
                    color: colorCard.background,
                },
                "& .MuiDivider-root": {
                    background: colorCard.tetriaryHover,
                },
                "& .MuiLink-root": {
                    color: colorCard.background,
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background,
                },
            },
            footerBottomWrapper: {
                background: colorCard.primary,
                "& .MuiLink-root": {
                    color: colorCard.background,
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background,
                },
            },
        },
        Header: {
            divider: {
                height: 1,
            },
        },
        LinkWithArrow: {
            linkWithIcon: {
                textDecoration: "none",
            },
            icon: {
                display: "block",
            },
        },
        LinkWithIcon: {
            link: {
                textDecoration: "none",
            },
        },
        TeaserFullPage: {
            root: {
                border: "none",
            },
        },
        WebshopEntryBlock: {
            withoutBackground: {
                "& .MuiOutlinedInput-input": {
                    background: colorCard.background2,
                },
            },
        },
    },
    navigation: {
        mobileCollapseBackgroundColor: colorCard.background,
        divider: colorCard.dividerLine,
    },
    contactUs: {
        backgroundColor: colorCard.dividerLine,
    },
    searchBoxContainer: {
        close: {
            placeholderColor: colorCard.bodyText,
        },
        open: {
            backgroundColor: colorCard.background2,
            borderColor: "#858585",
            borderWidth: "0 0 1px 0",
            borderStyle: "solid",
            placeholderColor: "#949494",
            inputColor: "#0A0A0A",
        },
    },
    searchResultsPage: {
        headerBackgroundColor: colorCard.tableOutline,
        headerTextColor: "#0A0A0A",
        subheadTextColor: "#5C5C5C",
        resultTitleColor: "#0A0A0A",
        resultDescriptionColor: colorCard.bodyText,
        resultLinkColor: colorCard.primary,
        resultLinkHoverColor: colorCard.helperText,
        resultLinkActiveColor: colorCard.info,
        dividerLineColor: "#EBEBEB",
    },
    icons: {
        searchIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Search" },
                React.createElement("path", { id: "Vector", "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M22 22L14.4638 14.4638L22 22ZM16.4928 9.24638C16.4928 13.6522 13.1884 16.4928 9.24638 16.4928C5.24638 16.4928 2 13.2464 2 9.24638C2 5.24638 5.24638 2 9.24638 2C13.2464 2 16.4928 5.24638 16.4928 9.24638Z", stroke: "#0A0A0A" })))),
        expandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M2 6.5L12 16.5L22 6.5", stroke: "black" }))),
        expandIconRotation: "180",
        expandIconNavigation: (React.createElement("path", { id: "VW_Icon_Down_S", d: "m12 18.4q-0.2 0-0.4-0.1-0.2 0-0.4-0.1-0.3-0.1-0.4-0.2-0.2-0.1-0.4-0.3l-10.4-10.6 1.2-1.1 10.4 10.6q0.1 0.1 0.2 0.1 0.1 0 0.2 0 0.1 0 0.2 0 0.1 0 0.2-0.1l10.4-10.6 1.2 1.1-10.3 10.6q-0.2 0.2-0.4 0.3-0.2 0.1-0.4 0.2-0.2 0.1-0.4 0.1-0.2 0.1-0.5 0.1z" })),
        expandIconRotationNavigation: "180",
        helpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12 15.1818V16.0909M10.5455 8.36364C10.5455 8.36364 14.2727 6.90909 14.7273 9.27273C15.0909 11 12 13.0909 12 13.0909V14.2727M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z", stroke: "black" }))),
        contactUsExpandIcon: (React.createElement("path", { id: "VW_Icon_Down_S", d: "m12 18.4q-0.2 0-0.4-0.1-0.2 0-0.4-0.1-0.3-0.1-0.4-0.2-0.2-0.1-0.4-0.3l-10.4-10.6 1.2-1.1 10.4 10.6q0.1 0.1 0.2 0.1 0.1 0 0.2 0 0.1 0 0.2 0 0.1 0 0.2-0.1l10.4-10.6 1.2 1.1-10.3 10.6q-0.2 0.2-0.4 0.3-0.2 0.1-0.4 0.2-0.2 0.1-0.4 0.1-0.2 0.1-0.5 0.1z" })),
        checkmarkIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4 12.871L9.54839 18.2903L20 5", stroke: "black" }))),
        checkmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 12.871L9.54839 18.2903L20 5" stroke="black" />
    </svg>`),
        heroCheckmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 12.871L9.54839 18.2903L20 5" stroke="#f2f2f2" />
    </svg>`),
        closeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4 20L20 4M4 4L20 20", stroke: "currentColor" }))),
        secondaryCloseIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4 20L20 4M4 4L20 20", stroke: "#0A0A0A" }))),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4 20L20 4M4 4L20 20", stroke: "currentColor" }))),
        bulletIconCSS: encodeURIComponent(`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="1.6"
        viewBox="0 0 14 1.6"
      >
        <line
          id="Line_861"
          data-name="Line 861"
          x2="14"
          transform="translate(0 0.8)"
          fill="none"
          stroke="#001e50"
          stroke-width="1.6"
        />
      </svg>
    `),
    },
    palette: {
        primary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        secondary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        error: {
            main: colorCard.error,
        },
        backgroundSecondary: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        background: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        text: {
            primary: colorCard.bodyText,
            secondary: colorCard.subhead,
            hint: colorCard.info,
        },
        divider: colorCard.dividerLine,
    },
    typography: {
        fontFamily: GetFontFamily("Audi Type Screen"),
        color: colorCard?.primary,
        root: {
            fontFamily: GetFontFamily("Audi Type Screen"),
        },
        h1: {
            fontFamily: GetFontFamily("Audi Type"),
            fontSize: "2.25rem",
            lineHeight: "2.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "2.5rem",
                lineHeight: "3rem",
            },
        },
        h2: {
            fontFamily: GetFontFamily("Audi Type"),
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "2rem",
                lineHeight: "2.5rem",
            },
        },
        h3: {
            fontFamily: GetFontFamily("Audi Type Screen"),
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },
        h4: {
            fontFamily: GetFontFamily("Audi Type Screen"),
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            fontSize: "1.125rem",
            lineHeight: "1.625rem",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        subhead: {
            fontFamily: GetFontFamily("Audi Type Screen"),
            color: colorCard?.subhead,
        },
        // helper text
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontFamily: GetFontFamily("Audi Type Screen"),
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontFamily: GetFontFamily("Audi Type Screen"),
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        body2: {
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        smallText: {
            fontSize: "0.875rem",
            lineHeight: "1.125rem",
            color: colorCard?.bodyText,
        },
    },
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: GetFontFamily("Audi Type Screen"),
            },
        },
        MuiLink: {
            underlineHover: {
                "a&": {
                    // to increase specificy
                    color: colorCard?.primary,
                    "&:hover": {
                        color: colorCard?.primaryHover,
                    },
                    "&:active": {
                        color: colorCard?.secondaryHover,
                    },
                },
            },
        },
        MuiMobileStepper: {
            root: {
                backgroundColor: colorCard?.background,
            },
            dot: {
                width: 16,
                height: 16,
                margin: "0 12px",
            },
        },
        MuiCardMedia: {
            root: {
                display: "flex",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "#00000000",
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 24,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
                border: "none",
            },
            input: {
                borderRadius: 0,
                border: `none`,
                borderBottom: `1px solid ${colorCard.inputOutline}`,
                "&::placeholder": {
                    color: colorCard.placeholderText,
                },
            },
            notchedOutline: {
                border: "none",
            },
        },
        MuiInputLabel: {
            root: {
                color: colorCard.bodyText,
            },
            formControl: {
                transform: "none",
                color: colorCard.bodyText,
                lineHeight: 1.75,
            },
            outlined: {
                "&$shrink": {
                    transform: "translate(10px, -20px) scale(1)",
                },
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: colorCard.background2,
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: 2,
            },
        },
        MuiPickersDay: {
            dayDisabled: {
                color: colorCard?.background3,
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: colorCard?.label,
            },
            iconButton: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiTooltip: {
            arrow: {
                color: colorCard?.subhead,
            },
            popper: {
                pointerEvents: "auto",
            },
        },
        MuiAccordionDetails: {
            root: {
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    paddingTop: 16,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fill: colorCard?.primary,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 0,
                "text-transform": "none",
            },
            outlined: {
                color: colorCard?.primary,
                borderColor: colorCard?.primary,
            },
            outlinedPrimary: {
                color: colorCard?.primary,
                borderColor: colorCard?.primary,
            },
            text: {
                "&:hover": {
                    backgroundColor: colorCard?.background,
                },
            },
            containedPrimary: {
                backgroundColor: colorCard?.primary,
                "&:hover": {
                    backgroundColor: colorCard?.primaryHover,
                },
            },
            contained: {
                boxShadow: "none",
                "&:hover": {
                    boxShadow: "none",
                },
                "&:active": {
                    boxShadow: "none",
                },
                "&$focusVisible": {
                    boxShadow: "none",
                },
                "a&": {
                    "&:hover": {
                        color: colorCard?.background,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    "&:active": {
                        color: colorCard?.background,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    color: colorCard?.background,
                    backgroundColor: colorCard?.primary,
                    textDecoration: "none",
                },
            },
        },
    },
};
export default createTheme(audi);
