import { BrandsEnum, GetBrandArea, GetBusinessArea, GetPageType, PageTypeEnum, } from "../../enums";
export function useStatisticsScript(statProps, startPageData) {
    if (startPageData) {
        const brand = startPageData.brand.value.toLowerCase();
        statProps.window.dataLayer = statProps.window.dataLayer || [];
        statProps.window.dataLayer.push({
            event: "Page Load",
            environmentInfo: {
                country: startPageData.pageCountry && startPageData.pageCountry.value !== ""
                    ? startPageData.pageCountry.value
                    : "Sweden",
                businessArea: GetBusinessArea(statProps.businessArea),
                portfolio: GetBrandArea(brand),
                partner: statProps.partner.toLowerCase() ?? brand, //use brand if value is not set explicitly
            },
            pageInfo: {
                pageType: GetPageType(statProps.pageType),
                pageLanguage: startPageData.language.name.toUpperCase(),
                pageUrl: statProps.href,
                pageTitle: statProps.pageTitle,
                pageReferrer: statProps.pageReferrer,
                canonicalPageUrl: statProps.href.split('?')[0],
                httpStatusCode: "200",
            },
            customerInfo: {
                loggedIn: false,
            },
        });
        if (GetPageType(statProps.pageType) === PageTypeEnum.Product ||
            (GetPageType(statProps.pageType) === PageTypeEnum.Home &&
                //volvia has unique case for now since no webshop on home page.
                //needs refactoring later when more custom cases
                brand != BrandsEnum.VolviaForetag &&
                brand != BrandsEnum.VolviaLastbilar)) {
            const uniqueEventId = 143;
            statProps.window.dataLayer.push({
                event: "Extra Page Load Info",
                productInfo: {
                    productName: {
                        localName: "Personbil",
                    },
                    currencyCode: "SEK",
                },
                processInfo: {
                    name: "webshop",
                    processStep: {
                        mainStep: "productpage",
                    },
                },
                "gtm.uniqueEventId": uniqueEventId,
            });
            const currBrand = brand;
            const eventData = {
                event: "Product Page Impression",
                ecommerce: {
                    currencyCode: "SEK",
                    detail: {
                        products: [
                            {
                                id: "Personbil",
                                name: "Personbil",
                                brand: GetBrandArea(currBrand),
                                quantity: 1,
                            },
                        ],
                    },
                },
                "gtm.uniqueEventId": uniqueEventId,
            };
            statProps.window.dataLayer.push(eventData);
        }
    }
    statProps.window.Bravi = statProps.window.Bravi || {};
    let shouldInit = !statProps.window.Bravi.gtm;
    statProps.window.Bravi.gtm = (function () {
        return function () {
            if (shouldInit) {
                shouldInit = false;
                (function (w, d, s, l, i) {
                    w[l] = w[l] || [];
                    w[l].push({
                        "gtm.start": new Date().getTime(),
                        event: "gtm.js",
                    });
                    const f = d.querySelector("head>script"), j = d.createElement(s), dl = l !== "dataLayer" ? "&l=" + l : "";
                    j.async = true;
                    j.src = "//www.googletagmanager.com/gtm.js?id=" + i.value + dl;
                    f.parentNode.insertBefore(j, f);
                })(statProps.window, document, "script", "dataLayer", startPageData.containerID);
            }
        };
    })();
    if (startPageData) {
        window.Bravi.gtm();
    }
    else {
        setTimeout(() => {
            if (startPageData) {
                window.Bravi.gtm();
            }
        }, 350);
    }
    return;
}
export default useStatisticsScript;
export const runErrorTrackingEvent = function (error, startPageData) {
    if (startPageData) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "Web Shop Server Error",
            customerInfo: {
                customerId: {
                    id: error?.EncryptedSSN,
                    type: "SSN",
                },
                age: 0,
                customerStatus: [
                    {
                        type: "if",
                    },
                    {
                        type: "carPartners",
                    },
                ],
            },
            processInfo: {
                name: "webshop",
                processStep: {
                    mainStep: "error",
                },
            },
            objectInfo: {
                objectId: {
                    id: error?.EncryptedRegNo,
                    type: "REG",
                },
                model: "car",
                objectSize: {
                    unit: "kg",
                },
            },
            insuranceInfo: {
                errorCode: error?.Error,
            },
            "gtm.uniqueEventId": 340,
        });
    }
};
export const runTrackingHook = function (result, startPageData, pageReferrer) {
    //set tracking maybe?
    console.log("Datalayer event fired");
    const businessArea = result.gaBusinessAreaDropdown
        .value;
    const pageType = result.gaPageTypeDropdown.value;
    const pageTitle = result.metaTitle.value;
    const partner = startPageData?.partner?.value.toLowerCase();
    const href = window.location.href;
    useStatisticsScript({ businessArea, pageType, pageTitle, pageReferrer, window, href, partner }, startPageData);
};
export const pushUserInteractionEvent = function (label, clickType) {
    window.dataLayer = window.dataLayer || [];
    if (label) {
        window.dataLayer.push({
            event: "userInteraction",
            clickInfo: {
                type: clickType,
                label: label,
            },
        });
    }
};
