import { Components } from "@episerver/spa-core";
import React from "react";
import { connect } from "react-redux";
import MOLayout from "./MOLayout";
import SkeletonLayout from "./SkeletonLayout";
import { ThemeProvider } from "@material-ui/core/styles";
import { getBrandedConfiguration } from "../../brandConfigurator";
import { PageContext, PageProvider, } from "../Context/PageContextProvider";
import NorwayBankIDAuthProvider from "../Context/NorwayBankIDAuthContext";
import ApplicationInsightsContextProvider from "../Context/ApplicationInsightsContext";
export const MOEntry = (props) => {
    function renderAlertBlock(alertArea) {
        return (React.createElement(Components.ContentArea, { data: alertArea, key: Math.random(), context: props.context }));
    }
    return (React.createElement(ApplicationInsightsContextProvider, null,
        React.createElement(PageProvider, null,
            React.createElement(NorwayBankIDAuthProvider, null,
                React.createElement(PageContext.Consumer, null, (ctx) => {
                    return (React.createElement(React.Fragment, null, (ctx.loadingData ||
                        !ctx.startPageData ||
                        !ctx.brand) ? (React.createElement(SkeletonLayout, null)) : (React.createElement(ThemeProvider, { theme: getBrandedConfiguration(ctx.brand).Theme },
                        React.createElement(MOLayout, null,
                            ctx.startPageData.alertArea.value
                                ? renderAlertBlock(ctx.startPageData.alertArea)
                                : null,
                            ctx.alertBlocksInContentPage &&
                                ctx.alertBlocksInContentPage.length > 0
                                ? ctx.alertBlocksInContentPage.map((alertArea) => {
                                    return renderAlertBlock(alertArea);
                                })
                                : null,
                            props.children)))));
                })))));
};
export const ConnectedCustomLayout = connect((state, ownProps) => {
    const propsFromState = state.OptiContentCloud || {};
    return { ...ownProps, ...propsFromState };
})(MOEntry);
export default ConnectedCustomLayout;
