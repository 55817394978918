import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import * as Helpers from "./common/Helpers";
import { placemnet } from "./common/Theme";
// create default theme to get breakpoints
const theme = createTheme();
const variables = {
    common: {
        borderRadius: 50,
    },
    colors: {
        primary: {
            main: "#001E50",
            dark: "#5F6467",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#00B0F0",
            dark: "#875735",
            contrastText: "#FFFFFF",
        },
        error: {
            main: "#E4002C",
        },
        text: {
            primary: "#070708",
            secondary: "#323537",
            hint: "#00B0F0",
        },
        background: "#FFFFFF",
        background2: "#F2F4F6",
        background3: "#DFE4E8",
        elementBackground: "#F6F8F9",
        border: "#DFE4E8",
        teaserBorder: "#F2F4F6",
        hover: "#5F6467",
        placeholder: "#999999",
        transparent: "#00000000",
        divider: "#DFE4E8",
        teaserBackground: "#B6BFC5",
        entryBackground: "#DFE4E8",
        icons: {
            main: "#001E50",
        },
        gdprText: "#5F6467",
        tooltipBackground: "#323537",
        gradient: "#D6EBF9",
    },
};
const defaultPathParams = {
    stroke: "currentColor",
    strokeWidth: "0.5",
    fill: "none",
    fillRule: "evenodd",
};
function getFontFamily(mainFont) {
    return [
        mainFont ?? "",
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
    ].join(",");
}
const props = Helpers.PrepareProperties(variables);
// More properties here: https://material-ui.com/customization/default-theme/
export const volkswagentransportbilar = {
    themeName: "Volkswagentransportbilar",
    brand: BrandsEnum.Volkswagentransportbilar,
    compositionBoxWidth: 816,
    containerMaxWidth: 384,
    pageMaxWidth: 1336,
    centred: "0 auto",
    border: "1px solid ",
    borderRadius: variables.common.borderRadius,
    shape: {
        borderRadius: variables.common.borderRadius,
    },
    heroWithHeadline: {
        textColor: variables.colors.primary.contrastText,
        body: {
            button: {
                primary: {
                    color: variables.colors.primary.main,
                    borderColor: variables.colors.primary.contrastText,
                    backgroundColor: variables.colors.primary.contrastText,
                },
                secondary: {
                    color: variables.colors.primary.contrastText,
                    borderColor: variables.colors.primary.contrastText,
                    backgroundColor: variables.colors.primary.main,
                },
            },
        },
    },
    header: {
        uspBannerBackground: variables.colors.primary.main,
        logoPlacement: placemnet.CENTER,
        dividerColor: variables.colors.primary.main,
        dividerHeight: 2,
        navigationLinkColor: variables.colors.primary.main,
    },
    breadcrumbs: {
        activeColor: variables.colors.text.primary,
        color: variables.colors.text.primary,
        iconColor: variables.colors.text.primary,
        activeFontFamily: getFontFamily("VWHeadWeb-Bold"),
    },
    topCarUSP: {
        gradientColor: variables.colors.gradient,
    },
    contentBox: {
        backgroundColor: variables.colors.background3,
        textColor: variables.colors.text.primary,
    },
    webshopEntry: {
        backgroundColor: variables.colors.elementBackground,
        gdprTextColor: variables.colors.gdprText,
    },
    USPContainerBlock: {
        backgroundColor: variables.colors.primary.main,
        textColor: variables.colors.primary.contrastText,
        headerColor: variables.colors.primary.contrastText,
    },
    mobileAppBlock: {
        backgroundColor: variables.colors.background,
    },
    customStyling: {
        global: {
            secondaryButton: {
                "&:hover": {
                    backgroundColor: "rgba(0, 30, 80, 0.04)",
                },
            },
            textArea: {
                legalText: {
                    color: variables.colors.gdprText,
                },
            },
        },
    },
    footer: {
        backgroundColor: variables.colors.background,
        border: true,
        borderColor: variables.colors.border,
        mobileFooterPlacement: placemnet.LEFT,
    },
    tooltip: {
        backgroundColor: variables.colors.tooltipBackground,
    },
    factAndIcon: {
        backgroundColor: variables.colors.background2,
    },
    publishDate: {
        color: variables.colors.gdprText,
    },
    searchBoxContainer: {
        close: {
            placeholderColor: "#101820",
        },
        open: {
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#75787B",
            borderRadius: "2px",
            placeholderColor: "#8B9196",
            inputColor: variables.colors.text.primary,
        }
    },
    searchResultsPage: {
        headerBackgroundColor: "#D9D9D6",
        headerTextColor: variables.colors.text.primary,
        filterSectionBackgroundColor: "#ECECE7",
        subheadTextColor: "#53565A",
        resultTitleColor: variables.colors.text.primary,
        resultDescriptionColor: "#101820",
        resultLinkColor: variables.colors.primary.main,
        resultLinkHoverColor: variables.colors.hover,
        resultLinkActiveColor: variables.colors.secondary.main,
        dividerLineColor: "#D9D9D6",
    },
    icons: {
        searchIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M22 20.5093L15.5404 15.354C16.9068 13.9255 17.7764 12 17.7764 9.8882C17.7764 5.54037 14.236 2 9.8882 2C5.54037 2 2 5.54037 2 9.8882C2 14.236 5.54037 17.7764 9.8882 17.7764C11.6894 17.7764 13.3665 17.1553 14.7329 16.0994L21.3168 21.3789L22 20.5093ZM3.11801 9.82609C3.11801 6.03727 6.16149 2.99379 9.95031 2.99379C13.677 2.99379 16.7826 6.03727 16.7826 9.82609C16.7826 13.6149 13.7391 16.6584 9.95031 16.6584C6.16149 16.6584 3.11801 13.6149 3.11801 9.82609Z", fill: "#001E50" }))),
        expandIcon: (React.createElement("path", { id: "VW_Icon_Down_S", d: "m12 18.4q-0.2 0-0.4-0.1-0.2 0-0.4-0.1-0.3-0.1-0.4-0.2-0.2-0.1-0.4-0.3l-10.4-10.6 1.2-1.1 10.4 10.6q0.1 0.1 0.2 0.1 0.1 0 0.2 0 0.1 0 0.2 0 0.1 0 0.2-0.1l10.4-10.6 1.2 1.1-10.3 10.6q-0.2 0.2-0.4 0.3-0.2 0.1-0.4 0.2-0.2 0.1-0.4 0.1-0.2 0.1-0.5 0.1z" })),
        expandIconRotation: "180",
        expandIconNavigation: (React.createElement("path", { id: "VW_Icon_Down_S", d: "m12 18.4q-0.2 0-0.4-0.1-0.2 0-0.4-0.1-0.3-0.1-0.4-0.2-0.2-0.1-0.4-0.3l-10.4-10.6 1.2-1.1 10.4 10.6q0.1 0.1 0.2 0.1 0.1 0 0.2 0 0.1 0 0.2 0 0.1 0 0.2-0.1l10.4-10.6 1.2 1.1-10.3 10.6q-0.2 0.2-0.4 0.3-0.2 0.1-0.4 0.2-0.2 0.1-0.4 0.1-0.2 0.1-0.5 0.1z" })),
        expandIconRotationNavigation: "180",
        helpIcon: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20.25", height: "20.25", viewBox: "0 0 20.25 20.25" },
            React.createElement("g", { id: "Icons", transform: "translate(-1.475 -1.475)" },
                React.createElement("path", { id: "Path_10969", "data-name": "Path 10969", d: "M11.6,21.6a10,10,0,1,1,10-10A10.043,10.043,0,0,1,11.6,21.6Zm0-19.185A9.185,9.185,0,1,0,20.785,11.6,9.208,9.208,0,0,0,11.6,2.415Z", transform: "translate(0 0)", fill: "#001e50", stroke: "#001e50", strokeWidth: "0.25" }),
                React.createElement("path", { id: "Path_10970", "data-name": "Path 10970", d: "M18.1,17.452h-.815V17.18c0-1.576.054-1.957,1.9-3.37,1.63-1.3,2.228-3.1,1.413-4.348a2.915,2.915,0,0,0-2.554-1.2A2.614,2.614,0,0,0,15.707,9.68L15,9.245A3.571,3.571,0,0,1,18.043,7.4,3.9,3.9,0,0,1,21.3,8.973c1.087,1.63.38,3.913-1.576,5.435-1.576,1.25-1.576,1.359-1.576,2.717Z", transform: "translate(-6.117 -2.645)", fill: "#001e50", stroke: "#001e50", strokeWidth: "0.25" }),
                React.createElement("circle", { id: "Ellipse_1522", "data-name": "Ellipse 1522", cx: "0.707", cy: "0.707", r: "0.707", transform: "translate(10.893 16.22)", fill: "#001e50", stroke: "#001e50", strokeWidth: "0.25" })))),
        contactUsExpandIcon: (React.createElement("path", { id: "VW_Icon_Down_S", d: "m12 18.4q-0.2 0-0.4-0.1-0.2 0-0.4-0.1-0.3-0.1-0.4-0.2-0.2-0.1-0.4-0.3l-10.4-10.6 1.2-1.1 10.4 10.6q0.1 0.1 0.2 0.1 0.1 0 0.2 0 0.1 0 0.2 0 0.1 0 0.2-0.1l10.4-10.6 1.2 1.1-10.3 10.6q-0.2 0.2-0.4 0.3-0.2 0.1-0.4 0.2-0.2 0.1-0.4 0.1-0.2 0.1-0.5 0.1z" })),
        checkmarkIcon: (React.createElement("svg", { id: "check_circle_black_24dp", xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24" },
            React.createElement("path", { id: "Path_56", "data-name": "Path 56", d: "M0,0H24V24H0Z", fill: "none" }),
            React.createElement("circle", { id: "Ellipse_1518", "data-name": "Ellipse 1518", cx: "10", cy: "10", r: "10", transform: "translate(2 1.516)", fill: "none" }),
            React.createElement("path", { id: "checkmark", d: "M7.2,18.66a.607.607,0,0,1-.384-.137L0,12.931.768,12,7.08,17.171,19.006,0,20,.691,7.7,18.4a.609.609,0,0,1-.414.254A.508.508,0,0,1,7.2,18.66Z", transform: "translate(2 2.187)", fill: "#029640" }))),
        checkmarkIconCSS: encodeURIComponent(`<svg
    id="check_circle_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path id="Path_56" data-name="Path 56" d="M0,0H24V24H0Z" fill="none" />
    <circle
      id="Ellipse_1518"
      data-name="Ellipse 1518"
      cx="10"
      cy="10"
      r="10"
      transform="translate(2 1.516)"
      fill="none"
    />
    <path
      id="checkmark"
      d="M7.2,18.66a.607.607,0,0,1-.384-.137L0,12.931.768,12,7.08,17.171,19.006,0,20,.691,7.7,18.4a.609.609,0,0,1-.414.254A.508.508,0,0,1,7.2,18.66Z"
      transform="translate(2 2.187)"
      fill="#029640"
    />
  </svg>`),
        heroCheckmarkIconCSS: encodeURIComponent(`<svg
    id="check_circle_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path id="Path_56" data-name="Path 56" d="M0,0H24V24H0Z" fill="none" />
    <circle
      id="Ellipse_1518"
      data-name="Ellipse 1518"
      cx="10"
      cy="10"
      r="10"
      transform="translate(2 1.516)"
      fill="none"
    />
    <path
      id="checkmark"
      d="M7.2,18.66a.607.607,0,0,1-.384-.137L0,12.931.768,12,7.08,17.171,19.006,0,20,.691,7.7,18.4a.609.609,0,0,1-.414.254A.508.508,0,0,1,7.2,18.66Z"
      transform="translate(2 2.187)"
      fill="#F2F4F6"
    />
  </svg>`),
        closeIcon: (React.createElement("svg", { id: "Icon-Close", xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24" },
            React.createElement("g", { id: "Rectangle_29", "data-name": "Rectangle 29", stroke: "#000", strokeWidth: "1", opacity: "0" },
                React.createElement("rect", { width: "24", height: "24", stroke: "none" }),
                React.createElement("rect", { x: "0.5", y: "0.5", width: "23", height: "23", fill: "none" })),
            React.createElement("g", { id: "close_black_24dp" },
                React.createElement("path", { id: "Path_4601", "data-name": "Path 4601", d: "M0,0H24V24H0Z", fill: "none" }),
                React.createElement("path", { id: "close", d: "M16,.841,15.159,0,8,7.159.841,0,0,.841,7.159,8,0,15.159.841,16,8,8.841,15.159,16,16,15.159,8.841,8Z", transform: "translate(4 3.516)", fill: "#001e50" })))),
        secondaryCloseIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M20 4.35699L19.159 3.51599L12 10.675L4.841 3.51599L4 4.35699L11.159 11.516L4 18.675L4.841 19.516L12 12.357L19.159 19.516L20 18.675L12.841 11.516L20 4.35699Z", fill: "#001E50" }))),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4 20L20 4M4 4L20 20", stroke: "#999" }))),
        bulletIconCSS: encodeURIComponent(`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="1.6"
        viewBox="0 0 14 1.6"
      >
        <line
          id="Line_861"
          data-name="Line 861"
          x2="14"
          transform="translate(0 0.8)"
          fill="none"
          stroke="#001e50"
          stroke-width="1.6"
        />
      </svg>
    `),
    },
    link: {
        showArrow: false,
        showUnderline: true,
    },
    accordionBlock: {
        expandedBackgroundColor: variables.colors.background,
        comparationTableBackground: variables.colors.background,
        comparationTableIconBackground: variables.colors.secondary.main,
        backgroundColor: variables.colors.background,
        borderColor: variables.colors.divider,
    },
    tetriaryLink: {
        underlined: true,
    },
    textBlock: {
        tableBackground: variables.colors.background2,
        tableBorderColor: variables.colors.divider,
        linkHoverColor: variables.colors.hover,
    },
    popup: {
        backgroundColor: variables.colors.elementBackground,
        headerColor: variables.colors.divider,
    },
    contactUs: {
        backgroundColor: variables.colors.background2,
        activeBackgroundColor: variables.colors.background2,
    },
    palette: {
        primary: variables.colors.primary,
        secondary: variables.colors.secondary,
        error: variables.colors.error,
        text: variables.colors.text,
        backgroundSecondary: {
            default: variables.colors.background,
            paper: variables.colors.primary.main,
        },
        background: {
            default: variables.colors.background,
            paper: variables.colors.divider,
        },
        divider: variables.colors.border,
        grey: {
            400: variables.colors.border,
        },
        icons: {
            main: variables.colors.icons.main,
        },
    },
    typography: {
        fontFamily: getFontFamily("VWTextWeb-Regular"),
        color: variables.colors.text.primary,
        root: {
            fontFamily: getFontFamily("VWTextWeb-Regular"),
        },
        h1: {
            fontFamily: getFontFamily("VWHeadWeb-Bold"),
            fontSize: "2.25rem",
            lineHeight: "2.75rem",
            color: variables.colors.text.primary,
            "word-break": "break-word",
            fontWeight: 400,
            [theme.breakpoints.up("md")]: {
                fontSize: "2.5rem",
                lineHeight: "3rem",
            },
        },
        h2: {
            fontFamily: getFontFamily("VWHeadWeb-Bold"),
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            color: variables.colors.text.primary,
            "word-break": "break-word",
            fontWeight: 400,
            [theme.breakpoints.up("md")]: {
                fontSize: "2rem",
                lineHeight: "2.5rem",
            },
        },
        h3: {
            fontFamily: getFontFamily("VWHeadWeb-Bold"),
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            color: variables.colors.text.secondary,
            "word-break": "break-word",
            fontWeight: 400,
            [theme.breakpoints.up("md")]: {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },
        h4: {
            fontFamily: getFontFamily("VWHeadWeb-Bold"),
            color: variables.colors.text.secondary,
            "word-break": "break-word",
            fontWeight: 400,
            fontSize: "1.125rem",
            lineHeight: "1.625rem",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        h5: {
            fontFamily: getFontFamily("VWHeadWeb-Bold"),
            fontSize: "1.125rem",
            color: variables.colors.primary.main,
            "word-break": "break-word",
            fontWeight: 400,
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        h6: {
            fontFamily: getFontFamily("VWHeadWeb-Bold"),
            color: variables.colors.secondary.main,
            "word-break": "break-word",
            fontSize: "1rem",
            lineHeight: "1.75rem",
        },
        // helper text
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontFamily: getFontFamily("VWTextWeb-Regular"),
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontFamily: getFontFamily("VWTextWeb-Regular"),
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: variables.colors.text.primary,
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: variables.colors.text.primary,
        },
        body2: {
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: variables.colors.primary.main,
        },
    },
    teaser: {
        root: {
            backgroundColor: variables.colors.background,
            border: true,
            borderColor: variables.colors.teaserBorder,
        },
        container: {
            backgroundColor: variables.colors.teaserBackground,
        },
        icon: {
            textIndent: "30px"
        }
    },
    navigation: {
        backgroundColor: variables.colors.elementBackground,
        divider: variables.colors.divider,
        iconColor: variables.colors.text.secondary,
        megaBackgroundColor: variables.colors.background2,
        mobileCollapseBackgroundColor: variables.colors.background,
    },
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: getFontFamily("VWTextWeb-Regular"),
            },
        },
        MuiLink: {
            underlineHover: {
                "a&": {
                    // to increase specificy
                    color: variables.colors.primary.main,
                    "&:hover": {
                        color: variables.colors.hover,
                    },
                    "&:active": {
                        color: variables.colors.text.hint,
                    },
                },
            },
        },
        MuiMobileStepper: {
            dot: {
                width: 16,
                height: 16,
                margin: "0 12px",
            },
        },
        MuiCardMedia: {
            root: {
                display: "flex",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: variables.colors.transparent,
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 24,
            },
        },
        MuiAccordionDetails: {
            root: {
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    paddingTop: 16,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fill: variables.colors.primary.main,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 2,
            },
            input: {
                borderRadius: 2,
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: 2,
            },
        },
        MuiPickersDay: {
            dayDisabled: {
                color: variables.colors.background3,
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: variables.colors.text.primary,
            },
            iconButton: {
                backgroundColor: variables.colors.background,
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: variables.colors.background,
            },
        },
        MuiTooltip: {
            arrow: {
                color: variables.colors.tooltipBackground,
            },
            popper: {
                pointerEvents: "auto",
            },
        },
        MuiButton: {
            root: {
                borderRadius: variables.common.borderRadius,
                "text-transform": "none",
            },
            outlined: {
                color: variables.colors.primary.main,
                borderColor: variables.colors.primary.main,
            },
            text: {
                "&:hover": {
                    backgroundColor: variables.colors.background,
                },
            },
            contained: {
                boxShadow: props.buttons.boxShadow,
                "&:hover": {
                    boxShadow: props.buttons.boxShadow,
                },
                "&:active": {
                    boxShadow: props.buttons.boxShadow,
                },
                "&$focusVisible": {
                    boxShadow: props.buttons.boxShadow,
                },
                "a&": {
                    "&:hover": {
                        color: variables.colors.primary.contrastText,
                        backgroundColor: variables.colors.primary.dark,
                    },
                    "&:active": {
                        color: variables.colors.primary.contrastText,
                        backgroundColor: variables.colors.primary.dark,
                    },
                    color: variables.colors.primary.contrastText,
                    backgroundColor: variables.colors.primary.main,
                    textDecoration: "none",
                },
            },
        },
    },
};
export default createTheme(volkswagentransportbilar);
