export var BrandsEnum;
(function (BrandsEnum) {
    BrandsEnum["None"] = "";
    BrandsEnum["Audi"] = "audi";
    BrandsEnum["Bmw"] = "bmw";
    BrandsEnum["BmwMO"] = "bmwmo";
    BrandsEnum["Dacia"] = "dacia";
    BrandsEnum["Ford"] = "ford";
    BrandsEnum["IfRullaVidare"] = "ifrullavidare";
    BrandsEnum["IfRullaVidareMO"] = "ifrullavidaremo";
    BrandsEnum["Isuzu"] = "isuzu";
    BrandsEnum["Kia"] = "kia";
    BrandsEnum["Cupra"] = "cupra";
    BrandsEnum["Mercedes"] = "mercedes";
    BrandsEnum["Mini"] = "mini";
    BrandsEnum["Nissan"] = "nissan";
    BrandsEnum["NissanNO"] = "nissanno";
    BrandsEnum["NissanNOMO"] = "nissannomo";
    BrandsEnum["NissanDK"] = "nissandk";
    BrandsEnum["NissanFI"] = "nissanfi";
    BrandsEnum["NissanFIMO"] = "nissanfimo";
    BrandsEnum["Opel"] = "opel";
    BrandsEnum["Polestar"] = "polestar";
    BrandsEnum["PolestarMO"] = "polestarmo";
    BrandsEnum["PolestarForetag"] = "polestarforetag";
    BrandsEnum["PolestarForetagMO"] = "polestarforetagmo";
    BrandsEnum["PolestarFI"] = "polestarfi";
    BrandsEnum["PolestarFIMO"] = "polestarfimo";
    BrandsEnum["PolestarForetagFI"] = "polestarforetagfi";
    BrandsEnum["PolestarForetagFIMO"] = "polestarforetagfimo";
    BrandsEnum["PolestarNO"] = "polestarno";
    BrandsEnum["PolestarNOMO"] = "polestarnomo";
    BrandsEnum["PolestarForetagNO"] = "polestarforetagno";
    BrandsEnum["PolestarForetagNOMO"] = "polestarforetagnomo";
    BrandsEnum["Porsche"] = "porsche";
    BrandsEnum["Renault"] = "renault";
    BrandsEnum["RenaultForetag"] = "renaultforetag";
    BrandsEnum["Saab"] = "saab";
    BrandsEnum["SaabMO"] = "saabmo";
    BrandsEnum["Seat"] = "seat";
    BrandsEnum["Skoda"] = "skoda";
    BrandsEnum["Subaru"] = "subaru";
    BrandsEnum["Volkswagen"] = "volkswagen";
    BrandsEnum["Volvia"] = "volvia";
    BrandsEnum["VolviaForetag"] = "volviaforetag";
    BrandsEnum["VolviaLastbilar"] = "volvialastbilar";
    BrandsEnum["Volkswagentransportbilar"] = "volkswagentransportbilar";
    BrandsEnum["VolviaFi"] = "volviafi";
    BrandsEnum["VolviaNo"] = "volviano";
    BrandsEnum["Fleet"] = "fleet";
    BrandsEnum["FleetMO"] = "fleetmo";
    BrandsEnum["MG"] = "mg";
    BrandsEnum["Telematics"] = "telematics";
})(BrandsEnum || (BrandsEnum = {}));
export var BrandAreaEnum;
(function (BrandAreaEnum) {
    BrandAreaEnum["None"] = "";
    BrandAreaEnum["IMF"] = "IMF";
    BrandAreaEnum["VRF"] = "VRF";
})(BrandAreaEnum || (BrandAreaEnum = {}));
export function GetBrandArea(brand) {
    switch (brand) {
        case BrandsEnum.Volvia:
        case BrandsEnum.Renault:
        case BrandsEnum.RenaultForetag:
        case BrandsEnum.Dacia:
        case BrandsEnum.Polestar:
        case BrandsEnum.PolestarMO:
        case BrandsEnum.PolestarForetag:
        case BrandsEnum.PolestarForetagMO:
        case BrandsEnum.PolestarFI:
        case BrandsEnum.PolestarFIMO:
        case BrandsEnum.PolestarForetagFI:
        case BrandsEnum.PolestarForetagFIMO:
        case BrandsEnum.PolestarNO:
        case BrandsEnum.PolestarNOMO:
        case BrandsEnum.PolestarForetagNO:
        case BrandsEnum.PolestarForetagNOMO:
        case BrandsEnum.VolviaForetag:
        case BrandsEnum.VolviaLastbilar:
        case BrandsEnum.VolviaFi:
        case BrandsEnum.VolviaNo:
            return BrandAreaEnum.VRF;
        case BrandsEnum.Audi:
        case BrandsEnum.Bmw:
        case BrandsEnum.BmwMO:
        case BrandsEnum.Ford:
        case BrandsEnum.IfRullaVidare:
        case BrandsEnum.IfRullaVidareMO:
        case BrandsEnum.Isuzu:
        case BrandsEnum.Kia:
        case BrandsEnum.Cupra:
        case BrandsEnum.Mercedes:
        case BrandsEnum.Mini:
        case BrandsEnum.Nissan:
        case BrandsEnum.Opel:
        case BrandsEnum.Porsche:
        case BrandsEnum.Saab:
        case BrandsEnum.SaabMO:
        case BrandsEnum.Seat:
        case BrandsEnum.Skoda:
        case BrandsEnum.Subaru:
        case BrandsEnum.Volkswagen:
        case BrandsEnum.Volkswagentransportbilar:
        case BrandsEnum.Fleet:
        case BrandsEnum.FleetMO:
        case BrandsEnum.NissanDK:
        case BrandsEnum.NissanFI:
        case BrandsEnum.NissanFIMO:
        case BrandsEnum.MG:
        case BrandsEnum.Telematics:
        case BrandsEnum.NissanNO:
            return BrandAreaEnum.IMF;
        default:
            throw new Error(`Can not match 'BrandArea' for brand '${brand}'!`);
    }
}
export var TeaserEnum;
(function (TeaserEnum) {
    TeaserEnum["Link"] = "link";
    TeaserEnum["Button"] = "button";
    TeaserEnum["LinkWithIcon"] = "linkWithIcon";
    TeaserEnum["LinkWithArrow"] = "linkWithArrow";
})(TeaserEnum || (TeaserEnum = {}));
export var ButtonEnum;
(function (ButtonEnum) {
    ButtonEnum["Contained"] = "contained";
    ButtonEnum["Outlined"] = "outlined";
})(ButtonEnum || (ButtonEnum = {}));
export var BusinessAreaEnum;
(function (BusinessAreaEnum) {
    BusinessAreaEnum["PRI"] = "PRI";
    BusinessAreaEnum["COM"] = "COM";
    BusinessAreaEnum["OTH"] = "Other";
})(BusinessAreaEnum || (BusinessAreaEnum = {}));
export function GetBusinessArea(businessAreaNumber) {
    switch (businessAreaNumber) {
        case 1:
            return BusinessAreaEnum.COM;
        case 2:
            return BusinessAreaEnum.OTH;
        default:
            return BusinessAreaEnum.PRI;
    }
}
export var PageTypeEnum;
(function (PageTypeEnum) {
    PageTypeEnum["OtherPage"] = "Other Page";
    PageTypeEnum["Product"] = "Product Page";
    PageTypeEnum["ProductInformation"] = "Product Information Page";
    PageTypeEnum["Webshop"] = "Webshop Page";
    PageTypeEnum["WebshopConfirmation"] = "Webshop Confirmation Page";
    PageTypeEnum["Claims"] = "Claims Page";
    PageTypeEnum["CustomerService"] = "Customer Service Page";
    PageTypeEnum["Home"] = "Home Page";
    PageTypeEnum["MyPages"] = "My Pages Page";
    PageTypeEnum["MyBusiness"] = "My Business Page";
    PageTypeEnum["AskForOffer"] = "Ask For Offer";
    PageTypeEnum["AskForOfferConfirmation"] = "Ask For Offer Confirmation Page";
})(PageTypeEnum || (PageTypeEnum = {}));
export var ClickType;
(function (ClickType) {
    ClickType["ButtonClick"] = "button click";
    ClickType["LinkClick"] = "link click";
    ClickType["LinkClickInternal"] = "link click internal";
})(ClickType || (ClickType = {}));
export function GetPageType(pageTypeNumber) {
    switch (pageTypeNumber) {
        case 1:
            return PageTypeEnum.Product;
        case 2:
            return PageTypeEnum.ProductInformation;
        case 3:
            return PageTypeEnum.Webshop;
        case 4:
            return PageTypeEnum.WebshopConfirmation;
        case 5:
            return PageTypeEnum.Claims;
        case 6:
            return PageTypeEnum.CustomerService;
        case 7:
            return PageTypeEnum.Home;
        case 8:
            return PageTypeEnum.MyPages;
        case 9:
            return PageTypeEnum.MyBusiness;
        case 10:
            return PageTypeEnum.AskForOffer;
        case 11:
            return PageTypeEnum.AskForOfferConfirmation;
        default:
            return PageTypeEnum.OtherPage;
    }
}
export var LocalizedMenuAfterWord;
(function (LocalizedMenuAfterWord) {
    LocalizedMenuAfterWord["default"] = "";
    LocalizedMenuAfterWord["sv"] = "\u00F6versikt";
    LocalizedMenuAfterWord["no"] = "oversikt";
    LocalizedMenuAfterWord["fi"] = "yleiskatsaus";
    LocalizedMenuAfterWord["dk"] = "oversigt";
})(LocalizedMenuAfterWord || (LocalizedMenuAfterWord = {}));
export var LocalizedMenuLabel;
(function (LocalizedMenuLabel) {
    LocalizedMenuLabel["default"] = "Menu";
    LocalizedMenuLabel["sv"] = "Meny";
    LocalizedMenuLabel["no"] = "Meny";
    LocalizedMenuLabel["fi"] = "Valikko";
    LocalizedMenuLabel["dk"] = "Menukort";
})(LocalizedMenuLabel || (LocalizedMenuLabel = {}));
