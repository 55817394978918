import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import { GetFontFamily } from "./common/Helpers";
// create default theme to get breakpoints
const theme = createTheme();
const colorCard = {
    primary: "#101820",
    secondary: "#FF7500",
    background: "#FFFFFF",
    cardBackground: "#FFFFFF",
    tableBackground: "#ECECE7",
    background2: "#FFFFFF",
    accordionClosed: "#D9D9D6",
    tableOutline: "#C8C9C7",
    dividerLine: "#D9D9D6",
    secondaryHover: "#53565A",
    background3: "#EAEAEA",
    wcagOutline: "#75787B",
    inputOutline: "#75787B",
    placeholderText: "#75787B",
    wcag: "#75787B",
    gdprText: "#53565A",
    helperText: "#53565A",
    primaryHover: "#53565A",
    tetriaryHover: "#53565A",
    subhead: "#101820",
    bodyText: "#101820",
    headlines: "#000000",
    label: "#000000",
    inputText: "#000000",
    success: "#00E676",
    successBackground: "#D6FBE9",
    info: "#4C94FE",
    infoBackground: "#E2EEFF",
    warning: "#F7EA48",
    warningBackground: "#FEFCE2",
    error: "#DE2D21",
    errorBackground: "#FADDDB",
};
// More properties here: https://material-ui.com/customization/default-theme/
export const polestarforetag = {
    themeName: "polestarforetag",
    colorCard: colorCard,
    brand: BrandsEnum.PolestarForetag,
    shape: {
        borderRadius: 0,
    },
    customStyling: {
        global: {
            tetriaryButton: {
                textDecoration: "underline",
            },
            tetriaryLink: {
                textDecoration: "underline",
            },
            textArea: {
                a: {
                    color: colorCard.subhead,
                    "&:active": {
                        color: colorCard.primaryHover
                    }
                },
                h1: {
                    color: colorCard.subhead,
                },
                h2: {
                    color: colorCard.subhead,
                },
                h3: {
                    color: colorCard.subhead,
                },
                h4: {
                    color: colorCard.subhead,
                },
                legalText: {
                    color: colorCard.gdprText,
                },
            },
            primaryButton: {
                backgroundColor: colorCard.subhead,
                borderColor: colorCard.subhead,
            },
            secondaryButton: {
                borderColor: colorCard.subhead,
                color: colorCard.bodyText,
                "&:hover": {
                    color: colorCard.primaryHover,
                    borderColor: colorCard.primaryHover,
                    backgroundColor: "transparent"
                },
            },
        },
        greencard: {
            gdprWrapper: {
                "& a": {
                    color: colorCard.gdprText,
                    "&:hover": {
                        color: colorCard.gdprText,
                    },
                    "&:active": {
                        color: colorCard.gdprText,
                    },
                },
            },
        },
        factAndIcon: {
            header: {
                color: colorCard.subhead,
            },
        },
        LinkWithArrow: {
            linkWithIcon: {
                textDecoration: "none",
                fontFamily: GetFontFamily("Polestar"),
            },
            icon: {
                display: "block",
                fill: colorCard.label,
                fontSize: "1.5rem",
                marginLeft: 6
            },
        },
        MegaMenu: {
            linkIcon: {
                fill: colorCard.label,
            },
        },
        LinkWithIcon: {
            link: {
                textDecoration: "none",
            },
        },
        BreadCrumbs: {
            wrapper: {
                backgroundColor: colorCard.tableBackground,
            },
        },
        ArticleTeaser: {
            border: `1px solid ${colorCard.tableOutline}`
        },
        AccordionBlock: {
            accordionBox: {
                borderTop: `1px solid ${colorCard.tableOutline}`,
                borderBottom: `1px solid ${colorCard.tableOutline}`,
                backgroundColor: colorCard.tableBackground,
            },
        },
        AccordionBlockContainer: {
            root: {
                borderLeft: `1px solid ${colorCard.tableOutline}`,
                borderRight: `1px solid ${colorCard.tableOutline}`,
            },
        },
        WebshopEntryBlock: {
            gdprLinkArea: {
                "& .text-area a": {
                    color: colorCard.gdprText,
                    "&:hover": {
                        color: colorCard.gdprText,
                    },
                    "&:active": {
                        color: colorCard.gdprText,
                    }
                },
            },
            fullBackgroundWrapper: {
                background: colorCard.gdprText,
                "& input[type=text]": {
                    backgroundColor: colorCard.tableBackground,
                },
                "& h4": {
                    color: colorCard.background,
                },
                "& h2": {
                    color: colorCard.background,
                },
                "& h3": {
                    color: colorCard.background,
                },
                "& p": {
                    color: colorCard.tableBackground,
                },
                "& span": {
                    color: colorCard.background,
                },
                "& label": {
                    color: colorCard.tableBackground,
                },
                "& .text-area p a": {
                    color: colorCard.tableBackground,
                    "&:hover": {
                        color: colorCard.tableBackground,
                    },
                },
                "& a": {
                    color: colorCard.background,
                    textDecoration: "underline",
                    "&:hover": {
                        color: colorCard.tableBackground,
                        textDecoration: "underline",
                    },
                },
            },
        },
        CardInformationBlock: {
            headerwrapper: {
                backgroundColor: colorCard.bodyText,
            },
            cardLinkInversed: {
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background,
                },
            },
            cardLink: {
                "& a": {
                    color: colorCard.label,
                },
            },
            teaserGridInversed: {
                backgroundColor: colorCard.bodyText,
            },
        },
        CustomizedTextBox: {
            textBoxInput: {
                backgroundColor: colorCard.tableBackground,
                "&::placeholder": {
                    color: colorCard?.placeholderText,
                    opacity: 1,
                },
            },
        },
        Navigation: {
            menuButtonText: {
                fontFamily: GetFontFamily("Polestar"),
            },
        },
        Item: {
            link: {
                fontFamily: GetFontFamily("Polestar"),
            },
            active: {
                fontFamily: GetFontFamily("Polestar"),
            },
        },
        NavigationItems: {
            wrapper: {
                backgroundColor: colorCard.tableBackground,
            },
            itemWrapper: {
                "&:hover": {
                    backgroundColor: colorCard.tableBackground,
                    borderLeft: `4px solid ${colorCard.secondary}`,
                    textDecoration: "underline",
                },
                "& .active": {
                    backgroundColor: colorCard.accordionClosed,
                    borderLeft: `4px solid ${colorCard.primary}`,
                    textDecoration: "underline",
                },
            },
            activeItem: {
                color: colorCard.subhead,
            },
            item: {
                "& div": {
                    fontFamily: GetFontFamily("Polestar"),
                },
                fontFamily: GetFontFamily("Polestar"),
            },
            thirdLevel: {
                fontFamily: GetFontFamily("Polestar"),
            },
            secondLevel: {
                fontFamily: GetFontFamily("Polestar"),
            },
        },
        BraviContentPage: {
            heroWithHeadlineBody: {
                primaryButton: {
                    color: colorCard.primary,
                    fontFamily: GetFontFamily("Polestar"),
                    backgroundColor: colorCard.tableBackground,
                    borderColor: colorCard.tableBackground,
                    "&:hover": {
                        color: colorCard.primary,
                        backgroundColor: colorCard.tableOutline,
                        borderColor: colorCard.tableOutline,
                    },
                },
                secondaryButton: {
                    color: colorCard.background,
                    fontFamily: GetFontFamily("Polestar"),
                    backgroundColor: colorCard.primary,
                    borderColor: colorCard.background,
                    "&:hover": {
                        color: colorCard.tableOutline,
                        backgroundColor: colorCard.primary,
                        borderColor: colorCard.tableOutline,
                    },
                },
                textArea: {
                    primaryButton: {
                        color: colorCard.primary,
                        fontFamily: GetFontFamily("Polestar"),
                        backgroundColor: colorCard.tableBackground,
                        borderColor: colorCard.tableBackground,
                        "&:hover": {
                            color: colorCard.primary,
                            backgroundColor: colorCard.tableOutline,
                            borderColor: colorCard.tableOutline,
                        },
                    },
                }
            }
        },
        Footer: {
            wrapper: {
                backgroundColor: colorCard.background,
                borderTop: `1px solid ${colorCard?.tableOutline}`,
                borderBottom: `1px solid ${colorCard?.tableOutline}`,
                "& .MuiLink-root": {
                    color: colorCard.primary,
                },
                "& .MuiSvgIcon-root": {
                    display: "none"
                },
            },
            footerBottomWrapper: {
                backgroundColor: colorCard.background,
                "& .MuiLink-root": {
                    color: colorCard.primary,
                    fontWeight: 700,
                },
                "& .MuiSvgIcon-root": {
                    display: "none"
                },
            },
            aboutFooterText: {
                color: colorCard.gdprText
            },
        },
        SubmitButtonElementBlock: {
            submitButton: {
                backgroundColor: colorCard.headlines,
            },
        },
    },
    teaser: {
        root: {
            backgroundColor: colorCard.cardBackground,
        },
        container: {
            backgroundColor: colorCard.background3,
        },
        icon: {
            backgroundPosition: "top left",
            textIndent: "30px",
        }
    },
    webshopEntry: {
        backgroundColor: colorCard.background3,
        inputFieldColor: colorCard.tableBackground
    },
    heroWithHeadline: {
        backgroundColor: colorCard.subhead,
        textColor: colorCard.background2,
    },
    header: {
        uspBannerBackground: colorCard.subhead,
        backgroundColor: colorCard.background2,
        navigationLinkColor: colorCard.label,
    },
    navigation: {
        mobileCollapseBackgroundColor: colorCard.background2,
        backgroundColor: colorCard.background2,
        divider: colorCard.dividerLine,
    },
    cardInformationBlock: {
        backgroundColor: colorCard.subhead,
        darkModeTextColor: colorCard.background2,
    },
    layout: {
        backgroundColor: colorCard.background2,
    },
    accordionBlock: {
        expandedBackgroundColor: colorCard.cardBackground,
        backgroundColor: colorCard.tableBackground,
    },
    contactUs: {
        backgroundColor: colorCard.dividerLine,
        activeBackgroundColor: colorCard.tableBackground,
    },
    factAndIcon: {
        backgroundColor: colorCard.cardBackground,
    },
    breadcrumbs: {
        activeColor: colorCard.subhead,
        color: colorCard.subhead,
    },
    USPContainerBlock: {
        backgroundColor: colorCard.subhead,
        textColor: colorCard.background2,
        headerColor: colorCard.background2,
    },
    searchBoxContainer: {
        open: {
            backgroundColor: colorCard.background,
            borderColor: colorCard.inputOutline,
            borderWidth: "1px",
            borderStyle: "solid",
            inputColor: colorCard.headlines,
            placeholderColor: "#949494",
        },
        close: {
            backgroundColor: colorCard.background,
            placeholderColor: colorCard.bodyText,
        },
    },
    searchResultsPage: {
        headerBackgroundColor: colorCard.dividerLine,
        headerTextColor: colorCard.headlines,
        subheadTextColor: colorCard.helperText,
        resultTileColor: "#0A0A0A",
        resultDescriptionColor: colorCard.bodyText,
        resultLinkColor: colorCard.bodyText,
        resultLinkHoverColor: colorCard.primaryHover,
        resultLinkActiveColor: colorCard.info,
    },
    icons: {
        searchIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M22 20.5093L15.5404 15.354C16.9068 13.9255 17.7764 12 17.7764 9.8882C17.7764 5.54037 14.236 2 9.8882 2C5.54037 2 2 5.54037 2 9.8882C2 14.236 5.54037 17.7764 9.8882 17.7764C11.6894 17.7764 13.3665 17.1553 14.7329 16.0994L21.3168 21.3789L22 20.5093ZM3.11801 9.82609C3.11801 6.03727 6.16149 2.99379 9.95031 2.99379C13.677 2.99379 16.7826 6.03727 16.7826 9.82609C16.7826 13.6149 13.7391 16.6584 9.95031 16.6584C6.16149 16.6584 3.11801 13.6149 3.11801 9.82609Z", fill: "#95572B" }))),
        expandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.2783 12.2786L23.0349 12.2786V11.7214L12.2783 11.7214V0.964892L11.7211 0.964891L11.7211 11.7214H0.964635V12.2786H11.7211L11.7211 23.0351L12.2783 23.0351V12.2786Z", fill: "black", stroke: "black", "stroke-width": "0.5" }))),
        expandIconRotation: "45",
        expandIconNavigation: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.2783 12.2786L23.0349 12.2786V11.7214L12.2783 11.7214V0.964892L11.7211 0.964891L11.7211 11.7214H0.964635V12.2786H11.7211L11.7211 23.0351L12.2783 23.0351V12.2786Z", fill: "black", stroke: "black", "stroke-width": "0.5" }))),
        expandIconRotationNavigation: "45",
        contactUsExpandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.2783 12.2786L23.0349 12.2786V11.7214L12.2783 11.7214V0.964892L11.7211 0.964891L11.7211 11.7214H0.964635V12.2786H11.7211L11.7211 23.0351L12.2783 23.0351V12.2786Z", fill: "black", stroke: "black", "stroke-width": "0.5" }))),
        checkmarkIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20.75C7.1875 20.75 3.25 16.8125 3.25 12C3.25 7.1875 7.1875 3.25 12 3.25C16.8125 3.25 20.75 7.1875 20.75 12C20.75 16.8125 16.8125 20.75 12 20.75Z", fill: "#101820" }),
            React.createElement("path", { d: "M18.25 8.8125L17.3751 7.9375L10.125 15.1875L6.62494 11.6875L5.75 12.5625L10.125 16.9375L18.25 8.8125Z", fill: "#101820" }))),
        closeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.49 20L11.9986 13.52L5.53001 20L4.01001 18.48L10.49 12L4.01001 5.53143L5.53001 4L12.01 10.48L18.49 4L19.9986 5.52L13.5186 12L19.9986 18.48L18.49 20Z", fill: "#05141F" }))),
        primaryButtonIcon: (React.createElement("svg", { width: "12", height: "12", viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { "clip-path": "url(#clip0_5632_472)" },
                React.createElement("path", { d: "M9.15001 6.25H0.5V5.75H9.15001H10.3571L9.50356 4.89645L5.65711 1.05L6 0.707107L11.2929 6L6 11.2929L5.65711 10.95L9.50356 7.10355L10.3571 6.25H9.15001Z", fill: "#FF7500", stroke: "#FF7500" })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip0_5632_472" },
                    React.createElement("rect", { width: "12", height: "12", fill: "white" }))))),
        linkArrowIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { "clip-path": "url(#clip0_53_204)" },
                React.createElement("path", { d: "M12 6L10.95 7.05L15.15 11.25H6V12.75H15.15L10.95 16.95L12 18L18 12L12 6Z", fill: "#FF7500" })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip0_53_204" },
                    React.createElement("rect", { width: "12", height: "12", fill: "white", transform: "translate(6 6)" }))))),
        secondaryCloseIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12 12.2955L17.7045 18L18 17.7045L12.2955 12L18 6.2955L17.7045 6L12 11.7045L6.2955 6L6 6.2955L11.7045 12L6 17.7045L6.2955 18L12 12.2955Z", fill: "#95572B", stroke: "#95572B", "stroke-width": "0.5" }))),
        secondaryHelpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M11.4444 17H12.5556V10.8889H11.4444V17ZM12 9.30778C12.1937 9.30778 12.3561 9.24222 12.4872 9.11111C12.6183 8.98 12.6839 8.81759 12.6839 8.62389C12.6839 8.43019 12.6183 8.26778 12.4872 8.13667C12.3561 8.00574 12.1937 7.94028 12 7.94028C11.8063 7.94028 11.6439 8.00574 11.5128 8.13667C11.3817 8.26778 11.3161 8.43019 11.3161 8.62389C11.3161 8.81759 11.3817 8.98 11.5128 9.11111C11.6439 9.24222 11.8063 9.30778 12 9.30778ZM12.0036 22C10.6208 22 9.32074 21.7376 8.10333 21.2128C6.88611 20.688 5.82722 19.9757 4.92667 19.0761C4.02611 18.1765 3.31324 17.1185 2.78806 15.9022C2.26269 14.6861 2 13.3866 2 12.0036C2 10.6208 2.26241 9.32074 2.78722 8.10333C3.31204 6.88611 4.02426 5.82722 4.92389 4.92667C5.82352 4.02611 6.88148 3.31324 8.09778 2.78806C9.31389 2.26269 10.6134 2 11.9964 2C13.3792 2 14.6793 2.26241 15.8967 2.78722C17.1139 3.31204 18.1728 4.02426 19.0733 4.92389C19.9739 5.82352 20.6868 6.88148 21.2119 8.09778C21.7373 9.31389 22 10.6134 22 11.9964C22 13.3792 21.7376 14.6793 21.2128 15.8967C20.688 17.1139 19.9757 18.1728 19.0761 19.0733C18.1765 19.9739 17.1185 20.6868 15.9022 21.2119C14.6861 21.7373 13.3866 22 12.0036 22ZM12 20.8889C14.4815 20.8889 16.5833 20.0278 18.3056 18.3056C20.0278 16.5833 20.8889 14.4815 20.8889 12C20.8889 9.51852 20.0278 7.41667 18.3056 5.69444C16.5833 3.97222 14.4815 3.11111 12 3.11111C9.51852 3.11111 7.41667 3.97222 5.69444 5.69444C3.97222 7.41667 3.11111 9.51852 3.11111 12C3.11111 14.4815 3.97222 16.5833 5.69444 18.3056C7.41667 20.0278 9.51852 20.8889 12 20.8889Z", fill: "#FFFFFF" }))),
        checkmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20.75C7.1875 20.75 3.25 16.8125 3.25 12C3.25 7.1875 7.1875 3.25 12 3.25C16.8125 3.25 20.75 7.1875 20.75 12C20.75 16.8125 16.8125 20.75 12 20.75Z" fill="#101820" />
                <path d="M18.25 8.8125L17.3751 7.9375L10.125 15.1875L6.62494 11.6875L5.75 12.5625L10.125 16.9375L18.25 8.8125Z" fill="#101820" />
            </svg>`),
        heroCheckmarkIconCSS: encodeURIComponent(`
      <svg 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none"
        xmlns="http://www.w3.org/2000/svg" 
      >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M2 12C2 13.9778 2.58649 15.9112 3.6853 17.5557C4.78411 19.2002 6.3459 20.4819 8.17316 21.2388C10.0004 21.9957 12.0111 22.1937 13.9509 21.8079C15.8907 21.422 17.6725 20.4696 19.0711 19.0711C20.4696 17.6725 21.422 15.8907 21.8078 13.9509C22.1937 12.0111 21.9957 10.0004 21.2388 8.17317C20.4819 6.3459 19.2002 4.78412 17.5557 3.6853C15.9112 2.58649 13.9778 2 12 2C9.34783 2 6.80429 3.05357 4.92893 4.92893C3.05356 6.8043 2 9.34784 2 12Z" 
        stroke="#F8F8F8"
      />
      <path 
        d="M10.4096 16.3374L6.72289 12.7952L6 13.5904L9.54217 17.0602C9.75904 17.3494 10.0482 17.494 10.4096 17.494C10.7711 17.494 11.1325 17.2771 11.3494 16.988L18 6.57831L17.0602 6L10.4096 16.3374Z" 
        fill="#F8F8F8"
      />
      </svg>`),
        bulletIconCSS: encodeURIComponent(`
  <svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2 12C2 13.9778 2.58649 15.9112 3.6853 17.5557C4.78411 19.2002 6.3459 20.4819 8.17316 21.2388C10.0004 21.9957 12.0111 22.1937 13.9509 21.8079C15.8907 21.422 17.6725 20.4696 19.0711 19.0711C20.4696 17.6725 21.422 15.8907 21.8078 13.9509C22.1937 12.0111 21.9957 10.0004 21.2388 8.17317C20.4819 6.3459 19.2002 4.78412 17.5557 3.6853C15.9112 2.58649 13.9778 2 12 2C9.34783 2 6.80429 3.05357 4.92893 4.92893C3.05356 6.8043 2 9.34784 2 12Z"
    stroke="#95572B"
  />
  <path
    d="M10.4096 16.3374L6.72289 12.7952L6 13.5904L9.54217 17.0602C9.75904 17.3494 10.0482 17.494 10.4096 17.494C10.7711 17.494 11.1325 17.2771 11.3494 16.988L18 6.57831L17.0602 6L10.4096 16.3374Z"
    fill="#95572B"
  />
</svg>
`),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.49 20L11.9986 13.52L5.53001 20L4.01001 18.48L10.49 12L4.01001 5.53143L5.53001 4L12.01 10.48L18.49 4L19.9986 5.52L13.5186 12L19.9986 18.48L18.49 20Z", fill: "currentColor" }))),
        helpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M11.4444 17H12.5556V10.8889H11.4444V17ZM12 9.30778C12.1937 9.30778 12.3561 9.24222 12.4872 9.11111C12.6183 8.98 12.6839 8.81759 12.6839 8.62389C12.6839 8.43019 12.6183 8.26778 12.4872 8.13667C12.3561 8.00574 12.1937 7.94028 12 7.94028C11.8063 7.94028 11.6439 8.00574 11.5128 8.13667C11.3817 8.26778 11.3161 8.43019 11.3161 8.62389C11.3161 8.81759 11.3817 8.98 11.5128 9.11111C11.6439 9.24222 11.8063 9.30778 12 9.30778ZM12.0036 22C10.6208 22 9.32074 21.7376 8.10333 21.2128C6.88611 20.688 5.82722 19.9757 4.92667 19.0761C4.02611 18.1765 3.31324 17.1185 2.78806 15.9022C2.26269 14.6861 2 13.3866 2 12.0036C2 10.6208 2.26241 9.32074 2.78722 8.10333C3.31204 6.88611 4.02426 5.82722 4.92389 4.92667C5.82352 4.02611 6.88148 3.31324 8.09778 2.78806C9.31389 2.26269 10.6134 2 11.9964 2C13.3792 2 14.6793 2.26241 15.8967 2.78722C17.1139 3.31204 18.1728 4.02426 19.0733 4.92389C19.9739 5.82352 20.6868 6.88148 21.2119 8.09778C21.7373 9.31389 22 10.6134 22 11.9964C22 13.3792 21.7376 14.6793 21.2128 15.8967C20.688 17.1139 19.9757 18.1728 19.0761 19.0733C18.1765 19.9739 17.1185 20.6868 15.9022 21.2119C14.6861 21.7373 13.3866 22 12.0036 22ZM12 20.8889C14.4815 20.8889 16.5833 20.0278 18.3056 18.3056C20.0278 16.5833 20.8889 14.4815 20.8889 12C20.8889 9.51852 20.0278 7.41667 18.3056 5.69444C16.5833 3.97222 14.4815 3.11111 12 3.11111C9.51852 3.11111 7.41667 3.97222 5.69444 5.69444C3.97222 7.41667 3.11111 9.51852 3.11111 12C3.11111 14.4815 3.97222 16.5833 5.69444 18.3056C7.41667 20.0278 9.51852 20.8889 12 20.8889Z", fill: "#101820" }))),
    },
    palette: {
        primary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        secondary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        error: {
            main: colorCard.error,
        },
        backgroundSecondary: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        background: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        text: {
            primary: colorCard.bodyText,
            secondary: colorCard.subhead,
            hint: colorCard.info,
        },
        divider: colorCard.dividerLine,
    },
    typography: {
        fontFamily: GetFontFamily("Polestar"),
        color: colorCard?.primary,
        root: {
            fontFamily: GetFontFamily("Polestar"),
        },
        h1: {
            fontFamily: GetFontFamily("Polestar"),
            fontSize: "2.25rem",
            lineHeight: "2.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "2.5rem",
                lineHeight: "3rem",
            },
        },
        h2: {
            fontFamily: GetFontFamily("Polestar"),
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "2rem",
                lineHeight: "2.5rem",
            },
        },
        h3: {
            fontFamily: GetFontFamily("Polestar"),
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            fontWeight: 700,
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },
        h4: {
            fontFamily: GetFontFamily("Polestar"),
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontSize: "1.125rem",
            fontWeight: 700,
            lineHeight: "1.625rem",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        subhead: {
            fontFamily: GetFontFamily("Polestar"),
            color: colorCard?.subhead,
            fontSize: "1.25rem",
        },
        // helper text
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontFamily: GetFontFamily("Polestar"),
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontFamily: GetFontFamily("Polestar"),
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        body2: {
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        smallText: {
            fontSize: "0.875rem",
            lineHeight: "1.125rem",
            color: colorCard?.bodyText,
        },
    },
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: GetFontFamily("Polestar"),
            },
        },
        MuiLink: {
            underlineHover: {
                "a&": {
                    // to increase specificy
                    color: colorCard?.headlines,
                    "&:hover": {
                        color: colorCard?.primaryHover,
                    },
                    "&:active": {
                        color: colorCard?.secondaryHover,
                    },
                },
            },
        },
        MuiMobileStepper: {
            root: {
                backgroundColor: colorCard?.background,
            },
            dot: {
                width: 16,
                height: 16,
                margin: "0 12px",
            },
        },
        MuiCardMedia: {
            root: {
                display: "flex",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "#00000000",
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 24,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
                border: "none",
            },
            input: {
                borderRadius: 0,
                backgroundColor: colorCard.tableBackground,
                borderBottom: `1px solid ${colorCard.inputOutline}`,
                "&::placeholder": {
                    color: colorCard.placeholderText,
                },
            },
            notchedOutline: {
                border: "none",
            },
        },
        MuiInputLabel: {
            root: {
                color: colorCard.bodyText,
            },
            formControl: {
                transform: "none",
                color: colorCard.bodyText,
                lineHeight: 1.75,
            },
            outlined: {
                "&$shrink": {
                    transform: "translate(10px, -20px) scale(1)",
                },
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: colorCard.background2,
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: 2,
            },
        },
        MuiPickersDay: {
            dayDisabled: {
                color: colorCard?.background3,
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: colorCard?.label,
            },
            iconButton: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiTooltip: {
            arrow: {
                color: colorCard?.subhead,
            },
            popper: {
                pointerEvents: "auto",
            },
        },
        MuiAccordionDetails: {
            root: {
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    paddingTop: 16,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fill: colorCard?.primary,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 0,
                "text-transform": "none",
            },
            outlined: {
                color: colorCard?.label,
                borderColor: colorCard?.label,
                "&:hover": {
                    color: colorCard?.primaryHover,
                    borderColor: colorCard?.primaryHover,
                    backgroundColor: "transparent",
                },
            },
            outlinedPrimary: {
                color: colorCard?.label,
                borderColor: colorCard?.label,
                "&:hover": {
                    color: colorCard?.primaryHover,
                    border: `1px solid ${colorCard?.primaryHover}`,
                    backgroundColor: "transparent",
                },
            },
            text: {
                "&:hover": {
                    backgroundColor: colorCard?.background,
                },
            },
            containedPrimary: {
                backgroundColor: colorCard?.label,
                "&:hover": {
                    backgroundColor: colorCard?.primaryHover,
                    "@media (hover: none)": {
                        backgroundColor: colorCard?.primaryHover,
                    },
                },
            },
            contained: {
                boxShadow: "none",
                "&:hover": {
                    boxShadow: "none",
                },
                "&:active": {
                    boxShadow: "none",
                },
                "&$focusVisible": {
                    boxShadow: "none",
                },
                "a&": {
                    "&:hover": {
                        color: colorCard?.cardBackground,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    "&:active": {
                        color: colorCard?.cardBackground,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    color: colorCard?.cardBackground,
                    backgroundColor: colorCard?.label,
                    textDecoration: "none",
                },
            },
        },
    },
};
export default createTheme(polestarforetag);
