import React, { createContext } from "react";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useHistory } from "react-router";
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { useEpiserver } from "@episerver/spa-core";
import OpenPagesConfig from "app/Infrastructure/OpenPagesConfig";
export const ApplicationInsightsContext = createContext({});
export const ApplicationInsightsContextProvider = ({ children }) => {
    const epiState = useEpiserver();
    if (!epiState.isServerSideRendering()) {
        var reactPlugin = new ReactPlugin();
        var appInsights = new ApplicationInsights({
            config: {
                connectionString: OpenPagesConfig.appInsightsConnectionString,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: useHistory() }
                }
            }
        });
        appInsights.loadAppInsights();
    }
    return (React.createElement(ApplicationInsightsContext.Provider, { value: {} }, children));
};
export default ApplicationInsightsContextProvider;
