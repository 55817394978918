import createTheme from "@material-ui/core/styles/createTheme";
import { volvia } from "./volvia";
import { volviaforetag } from "./volviaforetag";
import { volvialastbilar } from "./volvialastbilar";
import { volkswagentransportbilar } from "./volkswagentransportbilar";
import { volkswagen } from "./volkswagen";
import { audi } from "./audi";
import { mercedes } from "./mercedes";
import { volviafi } from "./volviafi";
import { volviano } from "./volviano";
import { kia } from "./kia";
import { bmw } from "./bmw";
import { bmwmo } from "./bmwmo";
import { cupra } from "./cupra";
import { skoda } from "./skoda";
import { ford } from "./ford";
import { renault } from "./renault";
import { renaultforetag } from "./renaultforetag";
import { mg } from "./mg";
import { porsche } from "./porsche";
import { seat } from "./seat";
import { dacia } from "./dacia";
import { nissan } from "./nissan";
import { nissanno } from "./nissanno";
import { nissannomo } from "./nissannomo";
import { nissandk } from "./nissandk";
import { nissanfi } from "./nissanfi";
import { nissanfimo } from "./nissanfimo";
import { subaru } from "./subaru";
import { polestar } from "./polestar";
import { polestarmo } from "./polestarmo";
import { polestarforetag } from "./polestarforetag";
import { polestarforetagmo } from "./polestarforetagmo";
import { polestarfi } from "./polestarfi";
import { polestarfimo } from "./polestarfimo";
import { polestarforetagfi } from "./polestarforetagfi";
import { polestarforetagfimo } from "./polestarforetagfimo";
import { polestarno } from "./polestarno";
import { polestarforetagno } from "./polestarforetagno";
import { polestarnomo } from "./polestarnomo";
import { polestarforetagnomo } from "./polestarforetagnomo";
import { saab } from "./saab";
import { isuzu } from "./isuzu";
import { ifrullavidare } from "./ifrullavidare";
import { ifrullavidaremo } from "./ifrullavidaremo";
import { fleet } from "./fleet";
import { fleetmo } from "./fleetmo";
import { telematics } from "./telematics";
// this is needed for storybook theme provider, and also for logic below
export const themes = [
    volvia,
    volviaforetag,
    volvialastbilar,
    volkswagen,
    volkswagentransportbilar,
    audi,
    bmw,
    bmwmo,
    kia,
    cupra,
    mercedes,
    volviafi,
    volviano,
    skoda,
    ford,
    renault,
    renaultforetag,
    mg,
    porsche,
    seat,
    dacia,
    nissan,
    nissanno,
    nissannomo,
    nissandk,
    nissanfi,
    nissanfimo,
    subaru,
    polestar,
    polestarmo,
    polestarforetag,
    polestarforetagmo,
    polestarfi,
    polestarfimo,
    polestarforetagfi,
    polestarforetagfimo,
    polestarno,
    polestarnomo,
    polestarforetagno,
    polestarforetagnomo,
    saab,
    isuzu,
    ifrullavidare,
    ifrullavidaremo,
    fleet,
    fleetmo,
    telematics,
];
// mapping branded themes with brands enum (input param to switch specific brand)
export function getBrandedTheme(brand) {
    let theme = themes.find((x) => x.brand === brand);
    if (theme) {
        return createTheme(theme);
    }
    throw new Error(`No matching theme found for brand '${brand}'!`);
}
