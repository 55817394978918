import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import { GetFontFamily } from "./common/Helpers";
// create default theme to get breakpoints
const theme = createTheme();
const colorCard = {
    primary: "#0054F0",
    secondary: "#1241A2",
    tertiary: "#CCCCCC",
    background: "#FFFFFF",
    cardBackground: "#FFFFFF",
    tableBackground: "#FFFFFF",
    background2: "#F6F3F0",
    accordionClosed: "#F6F3F0",
    tableOutline: "#F1ECE8",
    dividerLine: "#F1ECE8",
    background3: "#B7AEA8",
    wcagOutline: "#9F948F",
    inputOutline: "#9F948F",
    placeholderText: "#9F948F",
    wcag: "#6E625E",
    gdprText: "#6E625E",
    helperText: "#6E625E",
    primaryHover: "#1241A2",
    secondaryHover: "#FFFFFF",
    tetriaryHover: "#FFFFFF",
    subhead: "#331E11",
    bodyText: "#331E11",
    headlines: "#24150C",
    label: "#24150C",
    inputText: "#333333",
    success: "#217331",
    successBackground: "#E0F0DB",
    info: "#0054F0",
    infoBackground: "#E1E9F6",
    warning: "#FCC74F",
    warningBackground: "#F9EBC7",
    error: "#BB320C",
    errorBackground: "#F9E3D4",
    footerBackground: "#F6F3F0"
};
// More properties here: https://material-ui.com/customization/default-theme/
export const bmw = {
    themeName: "bmw",
    colorCard: colorCard,
    brand: BrandsEnum.Bmw,
    border: "1px solid ",
    shape: {
        borderRadius: 6,
    },
    customStyling: {
        global: {
            secondaryButton: {
                color: colorCard.secondary,
                backgroundColor: "transparent",
                fontFamily: GetFontFamily("IfSans"),
                fontWeight: 700,
                border: `2px solid ${colorCard.secondary}`,
                "&:hover": {
                    color: colorCard.primaryHover,
                    border: `2px solid ${colorCard.primaryHover}`,
                    backgroundColor: "transparent",
                },
            },
            primaryButton: {
                color: colorCard.background,
                backgroundColor: colorCard.primary,
                border: `1px solid ${colorCard.primary}`,
                fontWeight: 700,
                fontFamily: GetFontFamily("IfSans"),
                borderColor: colorCard.primary,
                "&:hover": {
                    color: colorCard.background,
                    backgroundColor: colorCard.primaryHover,
                    border: `1px solid ${colorCard.primaryHover}`,
                },
            },
            textArea: {
                a: {
                    color: colorCard.bodyText,
                    "&:hover": {
                        color: colorCard.primaryHover,
                    },
                    "&:active": {
                        color: colorCard.primaryHover,
                    }
                },
                legalText: {
                    color: colorCard.gdprText,
                },
                h1: {
                    fontWeight: 700
                },
                h2: {
                    fontWeight: 700
                },
                h3: {
                    fontWeight: 700
                },
            },
        },
        CallbackBlock: {
            gdprArea: {
                "& a": {
                    color: colorCard?.gdprText,
                    "&:hover": {
                        color: colorCard?.primary,
                    },
                },
            },
        },
        FactAndIcon: {
            root: {
                border: `1px solid ${colorCard.dividerLine}`,
            },
        },
        factAndIcon: {
            header: {
                color: colorCard.headlines
            }
        },
        FormParagraph: {
            link: {
                "& a": {
                    color: colorCard.headlines,
                    "&:hover": {
                        color: colorCard.primaryHover,
                    },
                }
            }
        },
        MegaMenu: {
            rootLink: {
                color: colorCard.headlines,
                "&:hover": {
                    textDecoration: "underline",
                },
            },
            linkIcon: {
                display: "none"
            },
        },
        USPContainerBlock: {
            wrapper: {
                background: `linear-gradient(to bottom, ${colorCard.bodyText} 0%, 
                ${colorCard.bodyText} 100%) no-repeat`,
            }
        },
        BreadCrumbs: {
            wrapper: {
                backgroundColor: colorCard.accordionClosed,
            },
        },
        Header: {
            menuButtonText: {
                color: colorCard.headlines,
            },
            loginText: {
                color: colorCard.headlines,
            },
            link: {
                hover: {
                    textDecoration: "underline",
                },
            },
        },
        LinkWithArrow: {
            linkWithIcon: {
                fontFamily: GetFontFamily("IfSans"),
                color: colorCard.primary,
                fontWeight: 700,
                lineHeight: "1.5rem",
                "&:hover": {
                    color: colorCard.primaryHover,
                },
            },
            icon: {
                fontSize: "1.5rem"
            }
        },
        LinkWithIcon: {
            link: {
                color: colorCard.primary,
                "&:hover": {
                    color: colorCard.primaryHover,
                },
            },
        },
        ArticleTeaser: {
            root: {
                border: `1px solid ${colorCard.tableOutline}`
            },
            link: {
                linkWithIcon: {
                    color: colorCard.primary,
                    "&:hover": {
                        color: colorCard.primaryHover,
                    },
                },
            },
        },
        USPBlock: {
            header: {
                color: "#FFFFFF"
            },
            text: {
                color: "#FFFFFF"
            }
        },
        TeaserFullPage: {
            teaserActions: {
                link: {
                    color: colorCard.primary,
                    "&:hover": {
                        color: colorCard.primaryHover,
                    },
                },
            },
        },
        ContactUsAreaTabHead: {
            rotatedDropdownIcon: {
                transform: "rotate(45deg)",
            },
            activeHeaderText: {
                color: colorCard.headlines,
            },
        },
        Item: {
            link: {
                hover: {
                    textDecoration: "underline",
                },
            },
        },
        Navigation: {
            appmenu: {
                backgroundColor: colorCard.background,
            },
            menuButtonText: {
                color: colorCard.headlines,
            },
        },
        NavigationItems: {
            wrapper: {
                backgroundColor: colorCard.background,
            },
            item: {
                hover: {
                    textDecoration: "underline",
                    textUnderlineOffset: '8px',
                },
            },
            text: {
                color: colorCard.bodyText,
            },
            itemWrapper: {
                "&:hover": {
                    backgroundColor: colorCard.accordionClosed,
                    borderLeft: `4px solid ${colorCard.primary}`,
                    textDecoration: "underline",
                },
                "& .active": {
                    backgroundColor: colorCard.accordionClosed,
                    borderLeft: `4px solid ${colorCard.primary}`,
                    textDecoration: "underline",
                },
            },
            activeItem: {
                fontWeight: 700,
                color: colorCard.bodyText
            },
        },
        IMFGreenCardBlock: {
            gdprWrapper: {
                "& a": {
                    color: colorCard.gdprText,
                    "&:hover": {
                        color: colorCard.primary,
                    },
                },
            },
        },
        WebshopEntryBlock: {
            gdprLinkArea: {
                "& .text-area p a": {
                    color: colorCard.gdprText,
                    "&:hover": {
                        color: colorCard.primaryHover,
                    },
                },
            },
            fullBackgroundWrapper: {
                background: colorCard.background3,
            },
        },
        CardInformationBlock: {
            cardLink: {
                linkWithIcon: {
                    color: colorCard.primary,
                    "&:hover": {
                        color: colorCard.primaryHover,
                    },
                },
            },
            headerwrapper: {
                backgroundColor: colorCard.secondary,
            }
        },
        BraviContentPage: {
            heroWithHeadlineBody: {
                textArea: {
                    primaryButton: {
                        color: colorCard.background,
                        fontFamily: GetFontFamily("IfSans"),
                        fontWeight: 700,
                        backgroundColor: colorCard.primary,
                        borderColor: colorCard.background,
                        "&:hover": {
                            color: colorCard.background,
                            backgroundColor: colorCard.primaryHover,
                            borderColor: colorCard.background
                        },
                    },
                },
                secondaryButton: {
                    borderColor: colorCard.background,
                    color: colorCard.background,
                    "&:hover": {
                        borderColor: colorCard.primaryHover,
                        backgroundColor: colorCard.wcag,
                    },
                },
            },
            heroWithHeadlineWrapper: {
                backgroundColor: colorCard.bodyText,
                "& h4": {
                    color: colorCard.background,
                },
                "& .text-area": {
                    color: colorCard.background,
                },
                "& h2": {
                    color: colorCard.background,
                },
                "& h1": {
                    color: colorCard.background,
                },
                "& h3": {
                    color: colorCard.background,
                },
                "& p": {
                    color: colorCard.background,
                },
                "& span": {
                    color: colorCard.background,
                },
                "& label": {
                    color: colorCard.background,
                },
            },
        },
        CustomizedTextBoxTooltip: {
            tooltipHeader: {
                color: colorCard.accordionClosed,
                fontWeight: 700,
            },
            tooltipBody: {
                color: colorCard.accordionClosed,
                fontWeight: 400,
            },
        },
        Footer: {
            wrapper: {
                "& .MuiTypography-h4": {
                    color: colorCard.background,
                },
                "& .MuiDivider-root": {
                    backgroundColor: colorCard.dividerLine,
                },
                "& .MuiLink-root": {
                    color: colorCard.primary,
                    textTransform: "none",
                    "&:hover": {
                        color: colorCard.primaryHover,
                    }
                },
                "& .MuiSvgIcon-root": {
                    display: "none",
                },
                background: "linear-gradient(to bottom, " + colorCard.footerBackground + " 0%, " + colorCard.footerBackground + " 100%) no-repeat",
            },
            footerBottomWrapper: {
                background: colorCard.footerBackground,
                color: colorCard.headlines,
                "& span": {
                    color: colorCard.bodyText,
                },
                "& .MuiLink-root": {
                    fontFamily: GetFontFamily("IfSans"),
                    color: colorCard.primary,
                    "&:hover": {
                        color: colorCard.primaryHover,
                    }
                },
                "& .MuiSvgIcon-root": {
                    display: "none",
                }
            },
        },
    },
    textBlock: {
        tableBackground: colorCard.tableOutline,
    },
    tooltip: {
        backgroundColor: colorCard.headlines,
    },
    contentBox: {
        backgroundColor: colorCard.background,
    },
    factAndIcon: {
        backgroundColor: colorCard.background,
    },
    accordionBlock: {
        backgroundColor: colorCard.accordionClosed,
        expandedBackgroundColor: colorCard.background,
        borderColor: colorCard.dividerLine,
    },
    navigation: {
        divider: colorCard.dividerLine,
    },
    footer: {
        backgroundColor: colorCard.footerBackground,
    },
    USPContainerBlock: {
        backgroundColor: colorCard.bodyText
    },
    teaser: {
        root: {
            border: false,
            borderColor: colorCard.accordionClosed
        }
    },
    breadcrumbs: {
        activeColor: colorCard.headlines,
        color: colorCard.subhead,
        iconColor: colorCard.secondary,
    },
    header: {
        uspBannerBackground: colorCard.bodyText,
        navigationLinkColor: colorCard.headlines,
    },
    heroWithHeadline: {
        textColor: colorCard.headlines,
        body: {
            button: {
                primary: {
                    color: colorCard.primary,
                    backgroundColor: colorCard.background,
                    borderColor: colorCard.background,
                },
                secondary: {
                    color: colorCard.background,
                    borderColor: colorCard.background,
                    backgroundColor: "transparent",
                },
            },
        },
    },
    tabsContainerBlock: {
        backgroundColor: colorCard.background,
        buttonTextColor: colorCard.secondary,
        buttonBorder: `1px solid ${colorCard.secondary}`,
        buttonHoverBorder: `1px solid ${colorCard.primaryHover}`,
        buttonActiveBorder: `2px solid ${colorCard.primaryHover}`,
        buttonActiveTextColor: colorCard.primaryHover,
    },
    webshopEntry: {
        gdprTextColor: colorCard.gdprText,
    },
    contactUs: {
        activeBackgroundColor: colorCard.accordionClosed,
        activeHeaderColor: colorCard.accordionClosed,
        backgroundColor: colorCard.dividerLine,
    },
    link: {
        showUnderline: false,
        showArrow: true
    },
    icons: {
        expandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.4734 12.4734L12.4734 22.6036C12.4734 22.7299 12.4339 22.8403 12.355 22.935C12.2761 23.0297 12.1578 23.077 12 23.077C11.8422 23.077 11.7239 23.0376 11.645 22.9587C11.5661 22.8798 11.5266 22.7614 11.5266 22.6036L11.5266 12.4734L1.39634 12.4734C1.27011 12.4734 1.15966 12.4339 1.06498 12.355C0.970305 12.2761 0.922968 12.1578 0.922968 12C0.922968 11.8422 0.962416 11.7239 1.04131 11.645C1.12021 11.5661 1.23855 11.5266 1.39634 11.5266L11.5266 11.5266L11.5266 1.39636C11.5266 1.27012 11.5661 1.15967 11.645 1.06499C11.7239 0.970318 11.8422 0.92298 12 0.92298C12.1578 0.92298 12.2761 0.962428 12.355 1.04132C12.4339 1.12022 12.4734 1.23856 12.4734 1.39636L12.4734 11.5266L22.6036 11.5266C22.7299 11.5266 22.8403 11.5661 22.935 11.645C23.0297 11.7239 23.077 11.8422 23.077 12C23.077 12.1578 23.0376 12.2761 22.9587 12.355C22.8798 12.4339 22.7614 12.4734 22.6036 12.4734L12.4734 12.4734Z", fill: "#0054F0" }))),
        expandIconRotation: "45",
        comparisonTableExpand: (React.createElement("svg", { width: "34", height: "34", viewBox: "0 0 34 34", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M11.9912 15.0371L12 15.0459L12.0088 15.0371L19.0823 7.96364C19.1854 7.86055 19.3102 7.80911 19.4576 7.80911C19.605 7.80911 19.7299 7.86055 19.833 7.96364C19.9361 8.06673 19.9875 8.1916 19.9875 8.33898C19.9875 8.48636 19.9361 8.61123 19.833 8.71433L12.9403 15.607C12.8059 15.7414 12.6568 15.8383 12.4929 15.8979C12.3285 15.9577 12.1642 15.9875 12 15.9875C11.8358 15.9875 11.6715 15.9577 11.5071 15.8979C11.3432 15.8383 11.1941 15.7414 11.0597 15.607L4.16703 8.71433C4.06394 8.61124 4.0125 8.48637 4.0125 8.33898C4.0125 8.1916 4.06394 8.06673 4.16703 7.96364C4.27012 7.86055 4.39499 7.80911 4.54237 7.80911C4.68975 7.80911 4.81462 7.86055 4.91771 7.96364L11.9912 15.0371Z", fill: "#0054F0", stroke: "#0054F0", "stroke-width": "0.025" }))),
        comparisonTableExpandRotation: "180",
        expandIconNavigation: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.4734 12.4734L12.4734 22.6036C12.4734 22.7299 12.4339 22.8403 12.355 22.935C12.2761 23.0297 12.1578 23.077 12 23.077C11.8422 23.077 11.7239 23.0376 11.645 22.9587C11.5661 22.8798 11.5266 22.7614 11.5266 22.6036L11.5266 12.4734L1.39634 12.4734C1.27011 12.4734 1.15966 12.4339 1.06498 12.355C0.970305 12.2761 0.922968 12.1578 0.922968 12C0.922968 11.8422 0.962416 11.7239 1.04131 11.645C1.12021 11.5661 1.23855 11.5266 1.39634 11.5266L11.5266 11.5266L11.5266 1.39636C11.5266 1.27012 11.5661 1.15967 11.645 1.06499C11.7239 0.970318 11.8422 0.92298 12 0.92298C12.1578 0.92298 12.2761 0.962428 12.355 1.04132C12.4339 1.12022 12.4734 1.23856 12.4734 1.39636L12.4734 11.5266L22.6036 11.5266C22.7299 11.5266 22.8403 11.5661 22.935 11.645C23.0297 11.7239 23.077 11.8422 23.077 12C23.077 12.1578 23.0376 12.2761 22.9587 12.355C22.8798 12.4339 22.7614 12.4734 22.6036 12.4734L12.4734 12.4734Z", fill: "#0054F0" }))),
        expandIconRotationNavigation: "45",
        contactUsExpandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.4734 12.4734L12.4734 22.6036C12.4734 22.7299 12.4339 22.8403 12.355 22.935C12.2761 23.0297 12.1578 23.077 12 23.077C11.8422 23.077 11.7239 23.0376 11.645 22.9587C11.5661 22.8798 11.5266 22.7614 11.5266 22.6036L11.5266 12.4734L1.39634 12.4734C1.27011 12.4734 1.15966 12.4339 1.06498 12.355C0.970305 12.2761 0.922968 12.1578 0.922968 12C0.922968 11.8422 0.962416 11.7239 1.04131 11.645C1.12021 11.5661 1.23855 11.5266 1.39634 11.5266L11.5266 11.5266L11.5266 1.39636C11.5266 1.27012 11.5661 1.15967 11.645 1.06499C11.7239 0.970318 11.8422 0.92298 12 0.92298C12.1578 0.92298 12.2761 0.962428 12.355 1.04132C12.4339 1.12022 12.4734 1.23856 12.4734 1.39636L12.4734 11.5266L22.6036 11.5266C22.7299 11.5266 22.8403 11.5661 22.935 11.645C23.0297 11.7239 23.077 11.8422 23.077 12C23.077 12.1578 23.0376 12.2761 22.9587 12.355C22.8798 12.4339 22.7614 12.4734 22.6036 12.4734L12.4734 12.4734Z", fill: "#0054F0" }))),
        checkmarkIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M10.3333 15.1034L7.34483 12.1149C7.2682 12.0383 7.1772 11.9952 7.07184 11.9856C6.96648 11.9761 6.8659 12.0192 6.77011 12.1149C6.67433 12.2107 6.62644 12.3065 6.62644 12.4023C6.62644 12.4981 6.67433 12.5939 6.77011 12.6897L9.72989 15.6494C9.9023 15.8218 10.1034 15.908 10.3333 15.908C10.5632 15.908 10.7644 15.8218 10.9368 15.6494L17.1724 9.41379C17.249 9.33716 17.2921 9.24617 17.3017 9.1408C17.3113 9.03544 17.2682 8.93487 17.1724 8.83908C17.0766 8.7433 16.9808 8.6954 16.8851 8.6954C16.7893 8.6954 16.6935 8.7433 16.5977 8.83908L10.3333 15.1034ZM12.005 22C10.6224 22 9.32224 21.7376 8.10465 21.2129C6.88705 20.6882 5.8279 19.976 4.92721 19.0765C4.02654 18.1769 3.31351 17.1191 2.7881 15.903C2.2627 14.687 2 13.3876 2 12.005C2 10.6224 2.26237 9.32224 2.7871 8.10466C3.31185 6.88705 4.02399 5.82791 4.92353 4.92724C5.82309 4.02655 6.8809 3.31351 8.09695 2.7881C9.31301 2.2627 10.6124 2 11.995 2C13.3776 2 14.6778 2.26237 15.8953 2.7871C17.1129 3.31185 18.1721 4.02399 19.0728 4.92353C19.9734 5.82309 20.6865 6.8809 21.2119 8.09695C21.7373 9.31301 22 10.6124 22 11.995C22 13.3776 21.7376 14.6778 21.2129 15.8953C20.6882 17.113 19.976 18.1721 19.0765 19.0728C18.1769 19.9735 17.1191 20.6865 15.903 21.2119C14.687 21.7373 13.3876 22 12.005 22ZM12 21.1954C14.567 21.1954 16.7414 20.3046 18.523 18.523C20.3046 16.7414 21.1954 14.567 21.1954 12C21.1954 9.43295 20.3046 7.25862 18.523 5.47701C16.7414 3.6954 14.567 2.8046 12 2.8046C9.43295 2.8046 7.25862 3.6954 5.47701 5.47701C3.6954 7.25862 2.8046 9.43295 2.8046 12C2.8046 14.567 3.6954 16.7414 5.47701 18.523C7.25862 20.3046 9.43295 21.1954 12 21.1954Z", fill: "#0054F0" }))),
        closeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Close", opacity: "0.7" },
                React.createElement("path", { id: "Vector", d: "M12 12.6695L4.83682 19.8326C4.74756 19.9219 4.64156 19.9721 4.51883 19.9833C4.39609 19.9944 4.27894 19.9442 4.16736 19.8326C4.05579 19.7211 4 19.6095 4 19.4979C4 19.3863 4.05579 19.2748 4.16736 19.1632L11.3305 12L4.16736 4.83682C4.0781 4.74756 4.02789 4.64156 4.01674 4.51883C4.00558 4.39609 4.05579 4.27894 4.16736 4.16736C4.27894 4.05579 4.39052 4 4.50209 4C4.61367 4 4.72524 4.05579 4.83682 4.16736L12 11.3305L19.1632 4.16736C19.2524 4.0781 19.3584 4.02789 19.4812 4.01674C19.6039 4.00558 19.7211 4.05579 19.8326 4.16736C19.9442 4.27894 20 4.39052 20 4.50209C20 4.61367 19.9442 4.72524 19.8326 4.83682L12.6695 12L19.8326 19.1632C19.9219 19.2524 19.9721 19.3584 19.9833 19.4812C19.9944 19.6039 19.9442 19.7211 19.8326 19.8326C19.7211 19.9442 19.6095 20 19.4979 20C19.3863 20 19.2748 19.9442 19.1632 19.8326L12 12.6695Z", fill: "#331E11" })))),
        tooltipCloseIcon: (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Close" },
                React.createElement("path", { id: "Vector", d: "M8 8.66946L0.83682 15.8326C0.747559 15.9219 0.641562 15.9721 0.518828 15.9833C0.396095 15.9944 0.27894 15.9442 0.167364 15.8326C0.055788 15.7211 0 15.6095 0 15.4979C0 15.3863 0.055788 15.2748 0.167364 15.1632L7.33054 8L0.167364 0.83682C0.0781032 0.747559 0.027894 0.641562 0.0167364 0.518828C0.0055788 0.396095 0.055788 0.27894 0.167364 0.167364C0.27894 0.055788 0.390516 0 0.502092 0C0.613668 0 0.725244 0.055788 0.83682 0.167364L8 7.33054L15.1632 0.167364C15.2524 0.0781032 15.3584 0.027894 15.4812 0.0167364C15.6039 0.0055788 15.7211 0.055788 15.8326 0.167364C15.9442 0.27894 16 0.390516 16 0.502092C16 0.613668 15.9442 0.725244 15.8326 0.83682L8.66946 8L15.8326 15.1632C15.9219 15.2524 15.9721 15.3584 15.9833 15.4812C15.9944 15.6039 15.9442 15.7211 15.8326 15.8326C15.7211 15.9442 15.6095 16 15.4979 16C15.3863 16 15.2748 15.9442 15.1632 15.8326L8 8.66946Z", fill: "0054F0" })))),
        heroCheckmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.3333 15.1034L7.34483 12.1149C7.2682 12.0383 7.1772 11.9952 7.07184 11.9856C6.96648 11.9761 6.8659 12.0192 6.77011 12.1149C6.67433 12.2107 6.62644 12.3065 6.62644 12.4023C6.62644 12.4981 6.67433 12.5939 6.77011 12.6897L9.72989 15.6494C9.9023 15.8218 10.1034 15.908 10.3333 15.908C10.5632 15.908 10.7644 15.8218 10.9368 15.6494L17.1724 9.41379C17.249 9.33716 17.2921 9.24617 17.3017 9.1408C17.3113 9.03544 17.2682 8.93487 17.1724 8.83908C17.0766 8.7433 16.9808 8.6954 16.8851 8.6954C16.7893 8.6954 16.6935 8.7433 16.5977 8.83908L10.3333 15.1034ZM12.005 22C10.6224 22 9.32224 21.7376 8.10465 21.2129C6.88705 20.6882 5.8279 19.976 4.92721 19.0765C4.02654 18.1769 3.31351 17.1191 2.7881 15.903C2.2627 14.687 2 13.3876 2 12.005C2 10.6224 2.26237 9.32224 2.7871 8.10466C3.31185 6.88705 4.02399 5.82791 4.92353 4.92724C5.82309 4.02655 6.8809 3.31351 8.09695 2.7881C9.31301 2.2627 10.6124 2 11.995 2C13.3776 2 14.6778 2.26237 15.8953 2.7871C17.1129 3.31185 18.1721 4.02399 19.0728 4.92353C19.9734 5.82309 20.6865 6.8809 21.2119 8.09695C21.7373 9.31301 22 10.6124 22 11.995C22 13.3776 21.7376 14.6778 21.2129 15.8953C20.6882 17.113 19.976 18.1721 19.0765 19.0728C18.1769 19.9735 17.1191 20.6865 15.903 21.2119C14.687 21.7373 13.3876 22 12.005 22ZM12 21.1954C14.567 21.1954 16.7414 20.3046 18.523 18.523C20.3046 16.7414 21.1954 14.567 21.1954 12C21.1954 9.43295 20.3046 7.25862 18.523 5.47701C16.7414 3.6954 14.567 2.8046 12 2.8046C9.43295 2.8046 7.25862 3.6954 5.47701 5.47701C3.6954 7.25862 2.8046 9.43295 2.8046 12C2.8046 14.567 3.6954 16.7414 5.47701 18.523C7.25862 20.3046 9.43295 21.1954 12 21.1954Z" fill="#0054F0"/>
        </svg>`),
        bulletIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.3333 15.1034L7.34483 12.1149C7.2682 12.0383 7.1772 11.9952 7.07184 11.9856C6.96648 11.9761 6.8659 12.0192 6.77011 12.1149C6.67433 12.2107 6.62644 12.3065 6.62644 12.4023C6.62644 12.4981 6.67433 12.5939 6.77011 12.6897L9.72989 15.6494C9.9023 15.8218 10.1034 15.908 10.3333 15.908C10.5632 15.908 10.7644 15.8218 10.9368 15.6494L17.1724 9.41379C17.249 9.33716 17.2921 9.24617 17.3017 9.1408C17.3113 9.03544 17.2682 8.93487 17.1724 8.83908C17.0766 8.7433 16.9808 8.6954 16.8851 8.6954C16.7893 8.6954 16.6935 8.7433 16.5977 8.83908L10.3333 15.1034ZM12.005 22C10.6224 22 9.32224 21.7376 8.10465 21.2129C6.88705 20.6882 5.8279 19.976 4.92721 19.0765C4.02654 18.1769 3.31351 17.1191 2.7881 15.903C2.2627 14.687 2 13.3876 2 12.005C2 10.6224 2.26237 9.32224 2.7871 8.10466C3.31185 6.88705 4.02399 5.82791 4.92353 4.92724C5.82309 4.02655 6.8809 3.31351 8.09695 2.7881C9.31301 2.2627 10.6124 2 11.995 2C13.3776 2 14.6778 2.26237 15.8953 2.7871C17.1129 3.31185 18.1721 4.02399 19.0728 4.92353C19.9734 5.82309 20.6865 6.8809 21.2119 8.09695C21.7373 9.31301 22 10.6124 22 11.995C22 13.3776 21.7376 14.6778 21.2129 15.8953C20.6882 17.113 19.976 18.1721 19.0765 19.0728C18.1769 19.9735 17.1191 20.6865 15.903 21.2119C14.687 21.7373 13.3876 22 12.005 22ZM12 21.1954C14.567 21.1954 16.7414 20.3046 18.523 18.523C20.3046 16.7414 21.1954 14.567 21.1954 12C21.1954 9.43295 20.3046 7.25862 18.523 5.47701C16.7414 3.6954 14.567 2.8046 12 2.8046C9.43295 2.8046 7.25862 3.6954 5.47701 5.47701C3.6954 7.25862 2.8046 9.43295 2.8046 12C2.8046 14.567 3.6954 16.7414 5.47701 18.523C7.25862 20.3046 9.43295 21.1954 12 21.1954Z" fill="#0054F0"/>
</svg>`),
        checkmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.3333 15.1034L7.34483 12.1149C7.2682 12.0383 7.1772 11.9952 7.07184 11.9856C6.96648 11.9761 6.8659 12.0192 6.77011 12.1149C6.67433 12.2107 6.62644 12.3065 6.62644 12.4023C6.62644 12.4981 6.67433 12.5939 6.77011 12.6897L9.72989 15.6494C9.9023 15.8218 10.1034 15.908 10.3333 15.908C10.5632 15.908 10.7644 15.8218 10.9368 15.6494L17.1724 9.41379C17.249 9.33716 17.2921 9.24617 17.3017 9.1408C17.3113 9.03544 17.2682 8.93487 17.1724 8.83908C17.0766 8.7433 16.9808 8.6954 16.8851 8.6954C16.7893 8.6954 16.6935 8.7433 16.5977 8.83908L10.3333 15.1034ZM12.005 22C10.6224 22 9.32224 21.7376 8.10465 21.2129C6.88705 20.6882 5.8279 19.976 4.92721 19.0765C4.02654 18.1769 3.31351 17.1191 2.7881 15.903C2.2627 14.687 2 13.3876 2 12.005C2 10.6224 2.26237 9.32224 2.7871 8.10466C3.31185 6.88705 4.02399 5.82791 4.92353 4.92724C5.82309 4.02655 6.8809 3.31351 8.09695 2.7881C9.31301 2.2627 10.6124 2 11.995 2C13.3776 2 14.6778 2.26237 15.8953 2.7871C17.1129 3.31185 18.1721 4.02399 19.0728 4.92353C19.9734 5.82309 20.6865 6.8809 21.2119 8.09695C21.7373 9.31301 22 10.6124 22 11.995C22 13.3776 21.7376 14.6778 21.2129 15.8953C20.6882 17.113 19.976 18.1721 19.0765 19.0728C18.1769 19.9735 17.1191 20.6865 15.903 21.2119C14.687 21.7373 13.3876 22 12.005 22ZM12 21.1954C14.567 21.1954 16.7414 20.3046 18.523 18.523C20.3046 16.7414 21.1954 14.567 21.1954 12C21.1954 9.43295 20.3046 7.25862 18.523 5.47701C16.7414 3.6954 14.567 2.8046 12 2.8046C9.43295 2.8046 7.25862 3.6954 5.47701 5.47701C3.6954 7.25862 2.8046 9.43295 2.8046 12C2.8046 14.567 3.6954 16.7414 5.47701 18.523C7.25862 20.3046 9.43295 21.1954 12 21.1954Z" fill="#0054F0" />
    </svg>`),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { opacity: "0.4" },
                React.createElement("path", { d: "M12 12.6695L4.83682 19.8326C4.74756 19.9219 4.64156 19.9721 4.51883 19.9833C4.39609 19.9944 4.27894 19.9442 4.16736 19.8326C4.05579 19.7211 4 19.6095 4 19.4979C4 19.3863 4.05579 19.2748 4.16736 19.1632L11.3305 12L4.16736 4.83682C4.0781 4.74756 4.02789 4.64156 4.01674 4.51883C4.00558 4.39609 4.05579 4.27894 4.16736 4.16736C4.27894 4.05579 4.39052 4 4.50209 4C4.61367 4 4.72524 4.05579 4.83682 4.16736L12 11.3305L19.1632 4.16736C19.2524 4.0781 19.3584 4.02789 19.4812 4.01674C19.6039 4.00558 19.7211 4.05579 19.8326 4.16736C19.9442 4.27894 20 4.39052 20 4.50209C20 4.61367 19.9442 4.72524 19.8326 4.83682L12.6695 12L19.8326 19.1632C19.9219 19.2524 19.9721 19.3584 19.9833 19.4812C19.9944 19.6039 19.9442 19.7211 19.8326 19.8326C19.7211 19.9442 19.6095 20 19.4979 20C19.3863 20 19.2748 19.9442 19.1632 19.8326L12 12.6695Z", fill: "#0A0A0A" })))),
        helpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12.001 17C12.1153 17 12.2107 16.9614 12.2874 16.8843C12.364 16.8072 12.4023 16.7117 12.4023 16.5977V11.2529C12.4023 11.1389 12.3636 11.0433 12.2864 10.9662C12.2091 10.8891 12.1133 10.8506 11.999 10.8506C11.8847 10.8506 11.7893 10.8891 11.7126 10.9662C11.636 11.0433 11.5977 11.1389 11.5977 11.2529V16.5977C11.5977 16.7117 11.6364 16.8072 11.7136 16.8843C11.7909 16.9614 11.8867 17 12.001 17ZM12 9.41379C12.1628 9.41379 12.2993 9.35872 12.4095 9.24856C12.5196 9.13841 12.5747 9.00192 12.5747 8.83908C12.5747 8.67625 12.5196 8.53975 12.4095 8.4296C12.2993 8.31944 12.1628 8.26437 12 8.26437C11.8372 8.26437 11.7007 8.31944 11.5905 8.4296C11.4804 8.53975 11.4253 8.67625 11.4253 8.83908C11.4253 9.00192 11.4804 9.13841 11.5905 9.24856C11.7007 9.35872 11.8372 9.41379 12 9.41379ZM12.005 22C10.6224 22 9.32224 21.7376 8.10465 21.2129C6.88705 20.6882 5.8279 19.976 4.92721 19.0765C4.02654 18.1769 3.31351 17.1191 2.7881 15.903C2.2627 14.687 2 13.3876 2 12.005C2 10.6224 2.26237 9.32224 2.7871 8.10466C3.31185 6.88705 4.02399 5.82791 4.92353 4.92724C5.82309 4.02655 6.8809 3.31351 8.09695 2.7881C9.31301 2.2627 10.6124 2 11.995 2C13.3776 2 14.6778 2.26237 15.8953 2.7871C17.1129 3.31185 18.1721 4.02399 19.0728 4.92353C19.9734 5.82309 20.6865 6.8809 21.2119 8.09695C21.7373 9.31301 22 10.6124 22 11.995C22 13.3776 21.7376 14.6778 21.2129 15.8953C20.6882 17.113 19.976 18.1721 19.0765 19.0728C18.1769 19.9735 17.1191 20.6865 15.903 21.2119C14.687 21.7373 13.3876 22 12.005 22ZM12 21.1954C14.567 21.1954 16.7414 20.3046 18.523 18.523C20.3046 16.7414 21.1954 14.567 21.1954 12C21.1954 9.43295 20.3046 7.25862 18.523 5.47701C16.7414 3.6954 14.567 2.8046 12 2.8046C9.43295 2.8046 7.25862 3.6954 5.47701 5.47701C3.6954 7.25862 2.8046 9.43295 2.8046 12C2.8046 14.567 3.6954 16.7414 5.47701 18.523C7.25862 20.3046 9.43295 21.1954 12 21.1954Z", fill: "#0054F0" }))),
        linkArrowIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M13.5141 13L9.9774 9.46328C9.92467 9.41055 9.8983 9.34652 9.8983 9.27119C9.8983 9.19586 9.92467 9.13183 9.9774 9.0791C10.0301 9.02637 10.0942 9 10.1695 9C10.2448 9 10.3089 9.02637 10.3616 9.0791L13.8079 12.5254C13.8757 12.5932 13.9247 12.6686 13.9548 12.7514C13.9849 12.8343 14 12.9171 14 13C14 13.0829 13.9849 13.1657 13.9548 13.2486C13.9247 13.3314 13.8757 13.4068 13.8079 13.4746L10.3616 16.9209C10.3089 16.9736 10.2448 17 10.1695 17C10.0942 17 10.0301 16.9736 9.9774 16.9209C9.92467 16.8682 9.89831 16.8041 9.89831 16.7288C9.89831 16.6535 9.92467 16.5895 9.9774 16.5367L13.5141 13Z", fill: "#0054F0", stroke: "#0054F0" })))
    },
    palette: {
        primary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        secondary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        error: {
            main: colorCard.error,
        },
        backgroundSecondary: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        background: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        text: {
            primary: colorCard.bodyText,
            secondary: colorCard.subhead,
            hint: colorCard.info,
        },
        divider: colorCard.dividerLine,
    },
    typography: {
        fontFamily: GetFontFamily("IfSans"),
        color: colorCard.primary,
        root: {
            fontFamily: GetFontFamily("IfSans"),
        },
        h1: {
            fontSize: "2.25rem",
            lineHeight: "2.75rem",
            color: colorCard.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "2.5rem",
                lineHeight: "3rem",
            },
        },
        h2: {
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            color: colorCard.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "2rem",
                lineHeight: "2.5rem",
            },
        },
        h3: {
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            color: colorCard.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            [theme.breakpoints.up("md")]: {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },
        h4: {
            color: colorCard.headlines,
            "word-break": "break-word",
            fontWeight: 700,
            fontSize: "1.125rem",
            lineHeight: "1.625rem",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        subhead: {
            color: colorCard.subhead,
            fontSize: "1.25rem",
            lineHeight: "1.625rem",
            fontWeight: 400,
        },
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        body2: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        smallText: {
            fontSize: "0.875rem",
            lineHeight: "1.375rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
    },
    overrides: {
        MuiTypography: {
            root: {},
            colorPrimary: {
                color: colorCard.primary,
            },
            colorTextSecondary: {
                color: colorCard?.bodyText,
            }
        },
        MuiLink: {
            underlineHover: {
                "&:hover": {
                    textDecoration: "none",
                },
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "#FFFFFF",
                },
            },
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderWidth: "1px",
                borderColor: colorCard.wcag,
            },
        },
        MuiInputBase: {
            root: {
                color: colorCard.placeholderText,
            },
            input: {
                color: colorCard.bodyText
            }
        },
        MuiFormLabel: {
            root: {
                color: colorCard?.headlines,
                "&.Mui-focused": {
                    color: colorCard?.headlines,
                },
                "&.Mui-error": {
                    color: colorCard?.error,
                },
            },
        },
        MuiButtonBase: {
            root: {
                fontFamily: GetFontFamily("IfSans"),
                fontWeight: 700
            },
        },
        MuiButton: {
            root: {
                fontFamily: GetFontFamily("IfSans"),
                fontWeight: 700,
                textTransform: "none",
            },
            outlinedPrimary: {
                color: colorCard?.secondary,
                backgroundColor: colorCard.background,
                border: `2px solid ${colorCard.secondary}`,
                "&:hover": {
                    color: colorCard?.primaryHover,
                    border: `2px solid ${colorCard.primaryHover}`,
                },
            },
            containedPrimary: {
                color: colorCard?.background,
                backgroundColor: colorCard?.primary,
                boxShadow: 'none',
                "&:hover": {
                    backgroundColor: colorCard?.primaryHover,
                    boxShadow: 'none',
                },
            },
        },
    },
};
export default createTheme(bmw);
