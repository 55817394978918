export const OpenPagesConfig = {
    //API service root for webshop related requests
    baseApiPath: window.document ? process.env.EPI_API_URL ?? "" : "",
    baseCmsPath: window.document ? process.env.EPI_CMS_URL ?? "" : "",
    webshopScriptPath: window.document ? process.env.WEBSHOP_SCRIPT_PATH ?? "" : "",
    webshopScriptPathNO: window.document ? process.env.WEBSHOP_SCRIPT_PATH_NO ?? "" : "",
    recaptchaKey: window.document ? process.env.RECAPTCHA_V3_KEY ?? "" : "",
    appInsightsConnectionString: window.document ? process.env.APP_INSIGHTS_CONNECTION_STRING ?? "" : ""
};
export default OpenPagesConfig;
