import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import { GetFontFamily } from "./common/Helpers";
// create default theme to get breakpoints
const theme = createTheme();
const colorCard = {
    primary: "#95572B",
    secondary: "#003E51",
    background: "#F8F8F8",
    cardBackground: "#FFFFFF",
    tableBackground: "#FFFFFF",
    background2: "#F8F8F8",
    accordionClosed: "#EAEAEA",
    tableOutline: "#DBD3CB",
    dividerLine: "#EAEAEA",
    secondaryHover: "#DBD3CB",
    background3: "#EAEAEA",
    wcagOutline: "#808080",
    inputOutline: "#808080",
    placeholderText: "#808080",
    wcag: "#6C757D",
    gdprText: "#6C757D",
    helperText: "#6C757D",
    primaryHover: "#6C757D",
    tetriaryHover: "#6C757D",
    subhead: "#242221",
    bodyText: "#242221",
    headlines: "#1A1A1A",
    label: "#1A1A1A",
    inputText: "#1A1A1A",
    success: "#28A745",
    successBackground: "#DDF1E1",
    info: "#007BFF",
    infoBackground: "#D6EAFF",
    warning: "#FFC107",
    warningBackground: "#FFF5D7",
    error: "#DC3545",
    errorBackground: "#F9DFE1",
};
// More properties here: https://material-ui.com/customization/default-theme/
export const cupra = {
    themeName: "cupra",
    colorCard: colorCard,
    brand: BrandsEnum.Cupra,
    shape: {
        borderRadius: 0,
    },
    customStyling: {
        global: {
            tetriaryButton: {
                textDecoration: "underline",
            },
            tetriaryLink: {
                textDecoration: "underline",
            },
            textArea: {
                a: {
                    color: colorCard.subhead,
                },
                h1: {
                    color: colorCard.subhead,
                },
                h2: {
                    color: colorCard.subhead,
                },
                h3: {
                    color: colorCard.subhead,
                },
                h4: {
                    color: colorCard.subhead,
                },
                legalText: {
                    color: colorCard.gdprText,
                },
            },
            primaryButton: {
                backgroundColor: colorCard.subhead,
                borderColor: colorCard.subhead,
            },
            secondaryButton: {
                borderColor: colorCard.subhead,
                "&:hover": {
                    borderWidth: 2,
                    backgroundColor: "rgba(149, 87, 43, 0.04)",
                },
            },
        },
        factAndIcon: {
            header: {
                color: colorCard.subhead,
            },
        },
        LinkWithArrow: {
            linkWithIcon: {
                textDecoration: "none",
                fontFamily: GetFontFamily("Cupra Regular"),
            },
            icon: {
                display: "block",
                fill: colorCard.label,
            },
        },
        MegaMenu: {
            linkIcon: {
                fill: colorCard.label,
            },
        },
        LinkWithIcon: {
            link: {
                textDecoration: "none",
            },
        },
        BreadCrumbs: {
            wrapper: {
                backgroundColor: colorCard.accordionClosed,
            },
        },
        AccordionBlock: {
            accordionBox: {
                borderTop: `1px solid ${colorCard.tableOutline}`,
                borderBottom: `1px solid ${colorCard.tableOutline}`,
            },
        },
        AccordionBlockContainer: {
            root: {
                borderLeft: `1px solid ${colorCard.tableOutline}`,
                borderRight: `1px solid ${colorCard.tableOutline}`,
            },
        },
        CardInformationBlock: {
            headerwrapper: {
                backgroundColor: colorCard.bodyText,
            },
            cardLinkInversed: {
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background,
                },
            },
            cardLink: {
                "& a": {
                    color: colorCard.label,
                },
            },
            teaserGridInversed: {
                backgroundColor: colorCard.bodyText,
            },
        },
        CustomizedTextBox: {
            textBoxInput: {
                backgroundColor: colorCard.cardBackground,
                "&::placeholder": {
                    color: colorCard?.placeholderText,
                    opacity: 1,
                },
            },
        },
        Navigation: {
            menuButtonText: {
                fontFamily: GetFontFamily("Cupra"),
            },
        },
        Item: {
            link: {
                fontFamily: GetFontFamily("Cupra"),
            },
            active: {
                fontFamily: GetFontFamily("Cupra"),
            },
        },
        NavigationItems: {
            activeItem: {
                color: colorCard.subhead,
            },
            item: {
                "& div": {
                    fontFamily: GetFontFamily("Cupra"),
                },
                fontFamily: GetFontFamily("Cupra"),
            },
            thirdLevel: {
                fontFamily: GetFontFamily("Cupra"),
            },
            secondLevel: {
                fontFamily: GetFontFamily("Cupra"),
            },
        },
        Footer: {
            wrapper: {
                borderTop: `1px solid ${colorCard?.tableOutline}`,
                borderBottom: `1px solid ${colorCard?.tableOutline}`,
                "& .MuiLink-root": {
                    color: colorCard.label,
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard.label,
                },
            },
            footerBottomWrapper: {
                "& .MuiLink-root": {
                    color: colorCard.label,
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard.label,
                },
            },
        },
        SubmitButtonElementBlock: {
            submitButton: {
                backgroundColor: colorCard.headlines,
            },
        },
    },
    teaser: {
        root: {
            backgroundColor: colorCard.cardBackground,
        },
        container: {
            backgroundColor: colorCard.background3,
        },
        icon: {
            backgroundPosition: "top left",
            textIndent: "30px",
        }
    },
    webshopEntry: {
        backgroundColor: colorCard.background3,
    },
    heroWithHeadline: {
        backgroundColor: colorCard.subhead,
        textColor: colorCard.background2,
    },
    header: {
        uspBannerBackground: colorCard.subhead,
        backgroundColor: colorCard.background2,
        navigationLinkColor: colorCard.label,
    },
    navigation: {
        mobileCollapseBackgroundColor: colorCard.background2,
        backgroundColor: colorCard.background2,
        divider: colorCard.dividerLine,
    },
    cardInformationBlock: {
        backgroundColor: colorCard.subhead,
        darkModeTextColor: colorCard.background2,
    },
    layout: {
        backgroundColor: colorCard.background2,
    },
    accordionBlock: {
        expandedBackgroundColor: colorCard.cardBackground,
        backgroundColor: colorCard.background3,
    },
    contactUs: {
        backgroundColor: colorCard.background3,
        activeBackgroundColor: colorCard.cardBackground,
    },
    factAndIcon: {
        backgroundColor: colorCard.cardBackground,
    },
    breadcrumbs: {
        activeColor: colorCard.subhead,
        color: colorCard.subhead,
    },
    USPContainerBlock: {
        backgroundColor: colorCard.subhead,
        textColor: colorCard.background2,
        headerColor: colorCard.background2,
    },
    searchBoxContainer: {
        open: {
            backgroundColor: colorCard.background,
            borderColor: colorCard.inputOutline,
            borderWidth: "1px",
            borderStyle: "solid",
            inputColor: colorCard.headlines,
            placeholderColor: "#949494",
        },
        close: {
            backgroundColor: colorCard.background,
            placeholderColor: colorCard.bodyText,
        },
    },
    searchResultsPage: {
        headerBackgroundColor: colorCard.dividerLine,
        headerTextColor: colorCard.headlines,
        subheadTextColor: colorCard.helperText,
        resultTileColor: "#0A0A0A",
        resultDescriptionColor: colorCard.bodyText,
        resultLinkColor: colorCard.bodyText,
        resultLinkHoverColor: colorCard.primaryHover,
        resultLinkActiveColor: colorCard.info,
    },
    icons: {
        searchIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M22 20.5093L15.5404 15.354C16.9068 13.9255 17.7764 12 17.7764 9.8882C17.7764 5.54037 14.236 2 9.8882 2C5.54037 2 2 5.54037 2 9.8882C2 14.236 5.54037 17.7764 9.8882 17.7764C11.6894 17.7764 13.3665 17.1553 14.7329 16.0994L21.3168 21.3789L22 20.5093ZM3.11801 9.82609C3.11801 6.03727 6.16149 2.99379 9.95031 2.99379C13.677 2.99379 16.7826 6.03727 16.7826 9.82609C16.7826 13.6149 13.7391 16.6584 9.95031 16.6584C6.16149 16.6584 3.11801 13.6149 3.11801 9.82609Z", fill: "#95572B" }))),
        expandIcon: (React.createElement("path", { id: "VW_Icon_Down_S", d: "m12 18.4q-0.2 0-0.4-0.1-0.2 0-0.4-0.1-0.3-0.1-0.4-0.2-0.2-0.1-0.4-0.3l-10.4-10.6 1.2-1.1 10.4 10.6q0.1 0.1 0.2 0.1 0.1 0 0.2 0 0.1 0 0.2 0 0.1 0 0.2-0.1l10.4-10.6 1.2 1.1-10.3 10.6q-0.2 0.2-0.4 0.3-0.2 0.1-0.4 0.2-0.2 0.1-0.4 0.1-0.2 0.1-0.5 0.1z" })),
        expandIconRotation: "180",
        expandIconNavigation: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M18.2949 9L12.2949 15L6.29492 9", stroke: "#95572B", "stroke-miterlimit": "10", "stroke-linecap": "square" }))),
        expandIconRotationNavigation: "180",
        contactUsExpandIcon: (React.createElement("path", { id: "VW_Icon_Down_S", d: "m12 18.4q-0.2 0-0.4-0.1-0.2 0-0.4-0.1-0.3-0.1-0.4-0.2-0.2-0.1-0.4-0.3l-10.4-10.6 1.2-1.1 10.4 10.6q0.1 0.1 0.2 0.1 0.1 0 0.2 0 0.1 0 0.2 0 0.1 0 0.2-0.1l10.4-10.6 1.2 1.1-10.3 10.6q-0.2 0.2-0.4 0.3-0.2 0.1-0.4 0.2-0.2 0.1-0.4 0.1-0.2 0.1-0.5 0.1z" })),
        checkmarkIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M2 12C2 13.9778 2.58649 15.9112 3.6853 17.5557C4.78411 19.2002 6.3459 20.4819 8.17316 21.2388C10.0004 21.9957 12.0111 22.1937 13.9509 21.8079C15.8907 21.422 17.6725 20.4696 19.0711 19.0711C20.4696 17.6725 21.422 15.8907 21.8078 13.9509C22.1937 12.0111 21.9957 10.0004 21.2388 8.17317C20.4819 6.3459 19.2002 4.78412 17.5557 3.6853C15.9112 2.58649 13.9778 2 12 2C9.34783 2 6.80429 3.05357 4.92893 4.92893C3.05356 6.8043 2 9.34784 2 12Z", stroke: "#95572B" }),
            React.createElement("path", { d: "M10.4096 16.3374L6.72289 12.7952L6 13.5904L9.54217 17.0602C9.75904 17.3494 10.0482 17.494 10.4096 17.494C10.7711 17.494 11.1325 17.2771 11.3494 16.988L18 6.57831L17.0602 6L10.4096 16.3374Z", fill: "#95572B" }))),
        closeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.49 20L11.9986 13.52L5.53001 20L4.01001 18.48L10.49 12L4.01001 5.53143L5.53001 4L12.01 10.48L18.49 4L19.9986 5.52L13.5186 12L19.9986 18.48L18.49 20Z", fill: "#05141F" }))),
        secondaryCloseIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12 12.2955L17.7045 18L18 17.7045L12.2955 12L18 6.2955L17.7045 6L12 11.7045L6.2955 6L6 6.2955L11.7045 12L6 17.7045L6.2955 18L12 12.2955Z", fill: "#95572B", stroke: "#95572B", "stroke-width": "0.5" }))),
        checkmarkIconCSS: encodeURIComponent(`<svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 12C2 13.9778 2.58649 15.9112 3.6853 17.5557C4.78411 19.2002 6.3459 20.4819 8.17316 21.2388C10.0004 21.9957 12.0111 22.1937 13.9509 21.8079C15.8907 21.422 17.6725 20.4696 19.0711 19.0711C20.4696 17.6725 21.422 15.8907 21.8078 13.9509C22.1937 12.0111 21.9957 10.0004 21.2388 8.17317C20.4819 6.3459 19.2002 4.78412 17.5557 3.6853C15.9112 2.58649 13.9778 2 12 2C9.34783 2 6.80429 3.05357 4.92893 4.92893C3.05356 6.8043 2 9.34784 2 12Z"
      stroke="#95572B"
    />
    <path
      d="M10.4096 16.3374L6.72289 12.7952L6 13.5904L9.54217 17.0602C9.75904 17.3494 10.0482 17.494 10.4096 17.494C10.7711 17.494 11.1325 17.2771 11.3494 16.988L18 6.57831L17.0602 6L10.4096 16.3374Z"
      fill="#95572B"
    />
  </svg>`),
        heroCheckmarkIconCSS: encodeURIComponent(`
      <svg 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none"
        xmlns="http://www.w3.org/2000/svg" 
      >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M2 12C2 13.9778 2.58649 15.9112 3.6853 17.5557C4.78411 19.2002 6.3459 20.4819 8.17316 21.2388C10.0004 21.9957 12.0111 22.1937 13.9509 21.8079C15.8907 21.422 17.6725 20.4696 19.0711 19.0711C20.4696 17.6725 21.422 15.8907 21.8078 13.9509C22.1937 12.0111 21.9957 10.0004 21.2388 8.17317C20.4819 6.3459 19.2002 4.78412 17.5557 3.6853C15.9112 2.58649 13.9778 2 12 2C9.34783 2 6.80429 3.05357 4.92893 4.92893C3.05356 6.8043 2 9.34784 2 12Z" 
        stroke="#F8F8F8"
      />
      <path 
        d="M10.4096 16.3374L6.72289 12.7952L6 13.5904L9.54217 17.0602C9.75904 17.3494 10.0482 17.494 10.4096 17.494C10.7711 17.494 11.1325 17.2771 11.3494 16.988L18 6.57831L17.0602 6L10.4096 16.3374Z" 
        fill="#F8F8F8"
      />
      </svg>`),
        bulletIconCSS: encodeURIComponent(`
  <svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2 12C2 13.9778 2.58649 15.9112 3.6853 17.5557C4.78411 19.2002 6.3459 20.4819 8.17316 21.2388C10.0004 21.9957 12.0111 22.1937 13.9509 21.8079C15.8907 21.422 17.6725 20.4696 19.0711 19.0711C20.4696 17.6725 21.422 15.8907 21.8078 13.9509C22.1937 12.0111 21.9957 10.0004 21.2388 8.17317C20.4819 6.3459 19.2002 4.78412 17.5557 3.6853C15.9112 2.58649 13.9778 2 12 2C9.34783 2 6.80429 3.05357 4.92893 4.92893C3.05356 6.8043 2 9.34784 2 12Z"
    stroke="#95572B"
  />
  <path
    d="M10.4096 16.3374L6.72289 12.7952L6 13.5904L9.54217 17.0602C9.75904 17.3494 10.0482 17.494 10.4096 17.494C10.7711 17.494 11.1325 17.2771 11.3494 16.988L18 6.57831L17.0602 6L10.4096 16.3374Z"
    fill="#95572B"
  />
</svg>
`),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.49 20L11.9986 13.52L5.53001 20L4.01001 18.48L10.49 12L4.01001 5.53143L5.53001 4L12.01 10.48L18.49 4L19.9986 5.52L13.5186 12L19.9986 18.48L18.49 20Z", fill: "currentColor" }))),
        helpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M19.2486 5.93503L12.3674 2.64979C11.8346 2.38342 11.2575 2.38342 10.7248 2.64979L4.02109 5.93503C3.39956 6.24579 3 6.86733 3 7.57765V17.3002C3 18.0105 3.39956 18.6764 4.06548 18.9872L10.6804 22.2724C10.9467 22.4056 11.2131 22.45 11.4795 22.45C11.7458 22.45 12.0122 22.4056 12.2786 22.2724L19.2486 18.9872C19.8701 18.6764 20.3141 18.0105 20.3141 17.3002V7.57765C20.2697 6.86733 19.8701 6.24579 19.2486 5.93503ZM19.6038 17.3002C19.6038 17.7441 19.3374 18.1881 18.9378 18.3657L11.9678 21.6509C11.657 21.8285 11.2575 21.8285 10.9467 21.6509L4.33185 18.3657C3.9323 18.1881 3.66593 17.7441 3.66593 17.3002V7.57765C3.66593 7.1337 3.9323 6.68975 4.33185 6.51217L11.0355 3.22693C11.2131 3.13814 11.3907 3.09374 11.5683 3.09374C11.7458 3.09374 11.9234 3.13814 12.101 3.22693L18.9822 6.51217C19.3818 6.68975 19.6482 7.1337 19.6482 7.57765V17.3002H19.6038ZM11.3019 16.8118H11.9678V10.6853H11.3019V16.8118ZM11.657 8.11039C11.3907 8.11039 11.2131 8.28797 11.2131 8.55434C11.2131 8.82071 11.3907 8.99829 11.657 8.99829C11.9234 8.99829 12.101 8.82071 12.101 8.55434C12.101 8.28797 11.879 8.11039 11.657 8.11039Z", fill: "#95572B", stroke: "#95572B", "stroke-width": "0.25" }))),
    },
    palette: {
        primary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        secondary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        error: {
            main: colorCard.error,
        },
        backgroundSecondary: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        background: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        text: {
            primary: colorCard.bodyText,
            secondary: colorCard.subhead,
            hint: colorCard.info,
        },
        divider: colorCard.dividerLine,
    },
    typography: {
        fontFamily: GetFontFamily("Cupra Book"),
        color: colorCard?.primary,
        root: {
            fontFamily: GetFontFamily("Cupra Book"),
        },
        h1: {
            fontFamily: GetFontFamily("Cupra Book"),
            fontSize: "2.25rem",
            lineHeight: "2.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "2.5rem",
                lineHeight: "3rem",
            },
        },
        h2: {
            fontFamily: GetFontFamily("Cupra Book"),
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "2rem",
                lineHeight: "2.5rem",
            },
        },
        h3: {
            fontFamily: GetFontFamily("Cupra"),
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            fontWeight: 700,
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },
        h4: {
            fontFamily: GetFontFamily("Cupra"),
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontSize: "1.125rem",
            fontWeight: 700,
            lineHeight: "1.625rem",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        subhead: {
            fontFamily: GetFontFamily("Cupra Book"),
            color: colorCard?.subhead,
            fontSize: "1.25rem",
        },
        // helper text
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontFamily: GetFontFamily("Cupra"),
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontFamily: GetFontFamily("Cupra Book"),
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        body2: {
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        smallText: {
            fontSize: "0.875rem",
            lineHeight: "1.125rem",
            color: colorCard?.bodyText,
        },
    },
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: GetFontFamily("Cupra Book"),
            },
        },
        MuiLink: {
            underlineHover: {
                "a&": {
                    // to increase specificy
                    color: colorCard?.headlines,
                    "&:hover": {
                        color: colorCard?.primaryHover,
                    },
                    "&:active": {
                        color: colorCard?.secondaryHover,
                    },
                },
            },
        },
        MuiMobileStepper: {
            root: {
                backgroundColor: colorCard?.background,
            },
            dot: {
                width: 16,
                height: 16,
                margin: "0 12px",
            },
        },
        MuiCardMedia: {
            root: {
                display: "flex",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "#00000000",
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 24,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
                border: "none",
            },
            input: {
                borderRadius: 0,
                border: `1px solid ${colorCard.inputOutline}`,
                "&::placeholder": {
                    color: colorCard.placeholderText,
                },
            },
            notchedOutline: {
                border: "none",
            },
        },
        MuiInputLabel: {
            root: {
                color: colorCard.bodyText,
            },
            formControl: {
                transform: "none",
                color: colorCard.bodyText,
                lineHeight: 1.75,
            },
            outlined: {
                "&$shrink": {
                    transform: "translate(10px, -20px) scale(1)",
                },
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: colorCard.background2,
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: 2,
            },
        },
        MuiPickersDay: {
            dayDisabled: {
                color: colorCard?.background3,
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: colorCard?.label,
            },
            iconButton: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiTooltip: {
            arrow: {
                color: colorCard?.subhead,
            },
            popper: {
                pointerEvents: "auto",
            },
        },
        MuiAccordionDetails: {
            root: {
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    paddingTop: 16,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fill: colorCard?.primary,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 0,
                "text-transform": "none",
            },
            outlined: {
                color: colorCard?.label,
                borderColor: colorCard?.label,
                "&:hover": {
                    borderWidth: 2,
                },
            },
            outlinedPrimary: {
                color: colorCard?.label,
                borderColor: colorCard?.label,
                "&:hover": {
                    borderWidth: 2,
                    borderColor: colorCard?.label,
                },
            },
            text: {
                "&:hover": {
                    backgroundColor: colorCard?.background,
                },
            },
            containedPrimary: {
                backgroundColor: colorCard?.label,
                "&:hover": {
                    backgroundColor: colorCard?.primaryHover,
                    "@media (hover: none)": {
                        backgroundColor: colorCard?.primaryHover,
                    },
                },
            },
            contained: {
                boxShadow: "none",
                "&:hover": {
                    boxShadow: "none",
                },
                "&:active": {
                    boxShadow: "none",
                },
                "&$focusVisible": {
                    boxShadow: "none",
                },
                "a&": {
                    "&:hover": {
                        color: colorCard?.cardBackground,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    "&:active": {
                        color: colorCard?.cardBackground,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    color: colorCard?.cardBackground,
                    backgroundColor: colorCard?.label,
                    textDecoration: "none",
                },
            },
        },
    },
};
export default createTheme(cupra);
