import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import { GetFontFamily } from "./common/Helpers";
// create default theme to get breakpoints
const theme = createTheme();
const colorCard = {
    primary: "#419468FF",
    secondary: "#78FAAE",
    tertiary: "#0E3A2FFF",
    background: "#FFFFFF",
    cardBackground: "#FFFFFF",
    tableBackground: "#FFFFFF",
    background2: "#F1F1F1",
    accordionClosed: "#D8D8D8",
    tableOutline: "#F1F1F1",
    dividerLine: "#D8D8D8",
    secondaryHover: "#464748",
    background3: "#D8D8D8",
    wcagOutline: "#7C7D7E",
    inputOutline: "#7C7D7E",
    placeholderText: "#7C7D7E",
    wcag: "#303132",
    gdprText: "#464748",
    helperText: "#464748",
    primaryHover: "#A8FFCC",
    tetriaryHover: "#464748",
    subhead: "#161718",
    bodyText: "#161718",
    headlines: "#161718",
    label: "#161718",
    inputText: "#161718",
    success: "#37D002",
    successBackground: "#DFF7D7",
    info: "#2D71D7",
    infoBackground: "#DDE8F9",
    warning: "#EC6513",
    warningBackground: "#FCE6D9",
    error: "#E82B37",
    errorBackground: "#FBDDDF",
};
// More properties here: https://material-ui.com/customization/default-theme/
export const skoda = {
    themeName: "skoda",
    colorCard: colorCard,
    brand: BrandsEnum.Skoda,
    shape: {
        borderRadius: 24,
    },
    customStyling: {
        global: {
            secondaryButton: {
                color: colorCard.background,
                backgroundColor: colorCard.wcag,
                fontFamily: GetFontFamily("Skoda-Next-Bold"),
                border: "none",
                "&:hover": {
                    backgroundColor: colorCard.tetriaryHover,
                    border: "none",
                },
            },
            primaryButton: {
                color: colorCard.bodyText,
                backgroundColor: colorCard.secondary,
                border: "none",
                fontFamily: GetFontFamily("Skoda-Next-Bold"),
                "&:hover": {
                    color: colorCard.bodyText,
                    backgroundColor: colorCard.primaryHover,
                    border: "none",
                },
                "& a": {
                    textDecoration: "none",
                    color: colorCard.bodyText,
                    "&:hover": {
                        textDecoration: "none",
                        color: colorCard.bodyText,
                    },
                },
            },
            textArea: {
                a: {
                    color: colorCard.primary,
                    "&:hover": {
                        color: colorCard.bodyText,
                    },
                },
                legalText: {
                    color: colorCard.gdprText,
                },
            },
        },
        SearchResult: {
            searchResultLink: {
                fontFamily: GetFontFamily("Skoda-Next-Regular"),
            }
        },
        MegaMenu: {
            rootLink: {
                color: colorCard.bodyText
            }
        },
        LinkWithArrow: {
            linkWithIcon: {
                textDecoration: "none",
            },
            icon: {
                display: "block",
            },
        },
        LinkWithIcon: {
            link: {
                textDecoration: "none",
            },
        },
        AccordionBlock: {
            accordionBox: {
                borderTop: `1px solid ${colorCard.accordionClosed}`,
                borderBottom: `1px solid ${colorCard.accordionClosed}`,
            },
        },
        AccordionBlockContainer: {
            root: {
                border: `1px solid ${colorCard.dividerLine}`,
            },
        },
        ArticleTeaser: {
            root: {
                border: `1px solid ${colorCard?.dividerLine}`,
            },
        },
        ContactUsAreaTabHead: {
            rotatedDropdownIcon: {
                transform: "rotate(45deg)",
            },
        },
        NavigationItems: {
            activeItem: {
                color: colorCard.subhead,
            },
        },
        WebshopEntryBlock: {
            fullBackgroundWrapper: {
                "& input[type=text]": {
                    backgroundColor: colorCard.background2,
                },
                "& h4": {
                    color: colorCard.background,
                },
                "& h2": {
                    color: colorCard.background,
                },
                "& h3": {
                    color: colorCard.background,
                },
                "& p": {
                    color: colorCard.background,
                },
                "& span": {
                    color: colorCard.background,
                },
                "& label": {
                    color: colorCard.background,
                },
                "& a": {
                    color: colorCard.background,
                    "&:hover": {
                        color: colorCard.background3,
                    }
                },
                "& button": {
                    "& span": {
                        color: colorCard.bodyText,
                    },
                },
            },
        },
        ContactUsAreaTabBody: {
            textColor: {
                "& h4": {
                    color: colorCard.background,
                },
                "& h2": {
                    color: colorCard.background,
                },
                "& h3": {
                    color: colorCard.background,
                },
                "& p": {
                    color: colorCard.background,
                },
                "& span": {
                    color: colorCard.background,
                },
                "& label": {
                    color: colorCard.background,
                },
                "& button": {
                    "& span": {
                        color: colorCard.bodyText,
                    },
                },
                "& a": {
                    "& span": {
                        color: colorCard.bodyText,
                    },
                },
            },
        },
        CardInformationBlock: {
            headerwrapper: {
                backgroundColor: colorCard.tertiary,
            },
            cardLinkInversed: {
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background,
                },
            },
            cardLink: {
                "& a": {
                    color: colorCard.bodyText
                }
            },
            teaserGridInversed: {
                backgroundColor: colorCard.tertiary,
            },
            darkModeHover: colorCard.accordionClosed
        },
        CustomizedTextBox: {
            textBoxInput: {
                "&::placeholder": {
                    color: colorCard?.placeholderText,
                    opacity: 1,
                },
            },
        },
        factAndIcon: {
            header: {
                color: colorCard?.bodyText,
            },
        },
        BreadCrumbs: {
            wrapper: {
                backgroundColor: colorCard.background2,
            },
            innerWrapper: {
                backgroundColor: colorCard.background2,
            },
            breadCrumbsBox: {
                "& .MuiLink-underlineHover": {
                    "& .MuiSvgIcon-root": {
                        fill: colorCard.primary,
                    }
                },
                "& a": {
                    fontFamily: 'Skoda-Next-Regular'
                }
            }
        },
        BraviContentPage: {
            heroWithHeadlineWrapper: {
                "& h4": {
                    color: colorCard.background,
                },
                "& .text-area": {
                    color: colorCard.background,
                },
                "& h2": {
                    color: colorCard.background,
                },
                "& h1": {
                    color: colorCard.background,
                },
                "& h3": {
                    color: colorCard.background,
                },
                "& p": {
                    color: colorCard.background,
                },
                "& span": {
                    color: colorCard.background,
                },
                "& label": {
                    color: colorCard.background,
                },
                "& a.button-secondary": {
                    border: `1px solid ${colorCard.background}`,
                    "&:hover": {
                        border: `2px solid ${colorCard.background}`
                    }
                }
            },
        },
        MOStartPage: {
            heroWithHeadline: {
                "& h4": {
                    color: colorCard.background,
                },
                "& .text-area": {
                    color: colorCard.background,
                },
                "& h2": {
                    color: colorCard.background,
                },
                "& h1": {
                    color: colorCard.background,
                },
                "& h3": {
                    color: colorCard.background,
                },
                "& p": {
                    color: colorCard.background,
                },
                "& span": {
                    color: colorCard.background,
                },
                "& label": {
                    color: colorCard.background,
                },
                "& button": {
                    "& span": {
                        color: colorCard.bodyText,
                    },
                },
                "& a.button-secondary": {
                    border: `1px solid ${colorCard.background}`,
                    "&:hover": {
                        border: `2px solid ${colorCard.background}`
                    }
                }
            },
        },
        Footer: {
            aboutFooterText: {
                fontSize: "0.875rem",
                color: colorCard.background,
            },
            wrapper: {
                borderTop: `1px solid ${colorCard?.inputOutline}`,
                borderBottom: `1px solid ${colorCard?.inputOutline}`,
                backgroundColor: colorCard.tertiary,
                "& .MuiTypography-h4": {
                    color: colorCard.background,
                },
                "& .MuiDivider-root": {
                    display: "none"
                },
                "& .MuiLink-root": {
                    color: colorCard.background,
                    fontFamily: GetFontFamily("Skoda-Next-Regular"),
                    "&:hover": {
                        color: colorCard.background3,
                    }
                },
                "& .MuiSvgIcon-root": {
                    display: "none"
                },
            },
            footerBottomWrapper: {
                background: colorCard.tertiary,
                "& .MuiLink-root": {
                    color: colorCard.background,
                    fontFamily: GetFontFamily("Skoda-Next-Regular"),
                    "&:hover": {
                        color: colorCard.background3,
                    }
                },
                "& .MuiSvgIcon-root": {
                    display: "none"
                },
            },
        },
    },
    navigation: {
        mobileCollapseBackgroundColor: colorCard.background2,
        divider: colorCard.dividerLine,
    },
    teaser: {
        icon: {
            textIndent: "30px",
            backgroundPosition: "top left",
        }
    },
    breadcrumbs: {
        activeColor: colorCard.label,
        color: colorCard.label,
        iconColor: colorCard.primary
    },
    USPContainerBlock: {
        backgroundColor: colorCard.tertiary,
        headerColor: colorCard.secondary,
        textColor: colorCard.secondary,
    },
    header: {
        uspBannerBackground: colorCard.tertiary,
        uspBannerTextColor: colorCard.secondary,
        navigationLinkColor: colorCard.bodyText,
    },
    heroWithHeadline: {
        backgroundColor: colorCard.tertiary,
        textColor: colorCard.background,
        body: {
            button: {
                primary: {
                    color: colorCard.bodyText,
                    backgroundColor: colorCard.secondary,
                    borderColor: colorCard.secondary,
                },
                secondary: {
                    color: colorCard.background,
                    borderColor: colorCard.background,
                    backgroundColor: "transparent",
                },
            },
        },
    },
    webshopEntry: {
        backgroundColor: colorCard.tertiary,
        inputFieldColor: colorCard.background2,
    },
    contactUs: {
        activeBackgroundColor: colorCard.tertiary,
        activeHeaderColor: colorCard.background,
    },
    tooltip: {
        backgroundColor: colorCard.gdprText,
    },
    cardInformationBlock: {
        backgroundColor: colorCard.tertiary,
    },
    link: {
        showArrow: false,
    },
    searchBoxContainer: {
        close: {
            placeholderColor: colorCard.wcag,
        },
        open: {
            borderWidth: "0 0 1px 0",
            borderColor: colorCard.inputOutline,
            borderStyle: "solid",
            backgroundColor: colorCard.background2,
            borderRadius: "4px 4px 0 0",
            placeholderColor: "#949494",
            inputColor: colorCard.headlines,
        }
    },
    searchResultsPage: {
        headerBackgroundColor: colorCard.dividerLine,
        headerTextColor: colorCard.headlines,
        subheadTextColor: colorCard.helperText,
        resultTitleColor: colorCard.headlines,
        resultDescriptionColor: colorCard.wcag,
        resultLinkColor: colorCard.primary,
        resultLinkHoverColor: colorCard.headlines,
        resultLinkActiveColor: colorCard.info,
    },
    icons: {
        searchIcon: (React.createElement("svg", { width: "20", height: "24", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Group" },
                React.createElement("path", { id: "Vector", d: "M20 18.5093L13.5404 13.354C14.9068 11.9255 15.7764 10 15.7764 7.8882C15.7764 3.54037 12.236 0 7.8882 0C3.54037 0 0 3.54037 0 7.8882C0 12.236 3.54037 15.7764 7.8882 15.7764C9.68944 15.7764 11.3665 15.1553 12.7329 14.0994L19.3168 19.3789L20 18.5093ZM1.11801 7.82609C1.11801 4.03727 4.16149 0.993788 7.95031 0.993788C11.677 0.993788 14.7826 4.03727 14.7826 7.82609C14.7826 11.6149 11.7391 14.6584 7.95031 14.6584C4.16149 14.6584 1.11801 11.6149 1.11801 7.82609Z", fill: "#161718" })))),
        expandIcon: (React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M9.20888 9.20895H17.2763V8.79105H9.20888L9.20888 0.723669L8.79098 0.723669V8.79105L0.723599 8.79105V9.20895L8.79098 9.20895V17.2763L9.20888 17.2763L9.20888 9.20895Z", fill: "#419468", stroke: "#419468", "stroke-width": "0.8" }))),
        expandIconRotation: "45",
        expandIconNavigation: (React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M9.20888 9.20895H17.2763V8.79105H9.20888L9.20888 0.723669L8.79098 0.723669V8.79105L0.723599 8.79105V9.20895L8.79098 9.20895V17.2763L9.20888 17.2763L9.20888 9.20895Z", fill: "#419468", stroke: "#419468", "stroke-width": "0.8" }))),
        expandIconRotationNavigation: "45",
        contactUsExpandIcon: (React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M9.20888 9.20895H17.2763V8.79105H9.20888L9.20888 0.723669L8.79098 0.723669V8.79105L0.723599 8.79105V9.20895L8.79098 9.20895V17.2763L9.20888 17.2763L9.20888 9.20895Z", fill: "#419468", stroke: "#419468", "stroke-width": "0.8" }))),
        checkmarkIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M3 11.5C3 9.24566 3.89553 7.08365 5.48959 5.48959C7.08365 3.89553 9.24566 3 11.5 3C13.7543 3 15.9163 3.89553 17.5104 5.48959C19.1045 7.08365 20 9.24566 20 11.5C20 13.7543 19.1045 15.9163 17.5104 17.5104C15.9163 19.1045 13.7543 20 11.5 20C9.24566 20 7.08365 19.1045 5.48959 17.5104C3.89553 15.9163 3 13.7543 3 11.5ZM11.5 2C10.2524 2 9.0171 2.24572 7.86451 2.72314C6.71191 3.20056 5.66464 3.90033 4.78249 4.78249C3.90033 5.66464 3.20056 6.71191 2.72314 7.86451C2.24572 9.0171 2 10.2524 2 11.5C2 12.7476 2.24572 13.9829 2.72314 15.1355C3.20056 16.2881 3.90033 17.3354 4.78249 18.2175C5.66464 19.0997 6.71191 19.7994 7.86451 20.2769C9.0171 20.7543 10.2524 21 11.5 21C14.0196 21 16.4359 19.9991 18.2175 18.2175C19.9991 16.4359 21 14.0196 21 11.5C21 8.98044 19.9991 6.56408 18.2175 4.78249C16.4359 3.00089 14.0196 2 11.5 2ZM9.854 14.854L16.354 8.354L15.646 7.646L9.5 13.793L7.354 11.646L6.646 12.354L9.146 14.854L9.5 15.207L9.854 14.854Z", fill: "#419468" }))),
        closeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M12 12.2955L17.7045 18L18 17.7045L12.2955 12L18 6.2955L17.7045 6L12 11.7045L6.2955 6L6 6.2955L11.7045 12L6 17.7045L6.2955 18L12 12.2955Z", fill: "#419468", stroke: "#419468", "stroke-width": "0.8" }))),
        secondaryCloseIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Close" },
                React.createElement("path", { id: "Close_2", d: "M12 12.2955L17.7045 18L18 17.7045L12.2955 12L18 6.2955L17.7045 6L12 11.7045L6.2955 6L6 6.2955L11.7045 12L6 17.7045L6.2955 18L12 12.2955Z", fill: "#161718", stroke: "#161718", "stroke-width": "0.8" })))),
        checkmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 11.5C3 9.24566 3.89553 7.08365 5.48959 5.48959C7.08365 3.89553 9.24566 3 11.5 3C13.7543 3 15.9163 3.89553 17.5104 5.48959C19.1045 7.08365 20 9.24566 20 11.5C20 13.7543 19.1045 15.9163 17.5104 17.5104C15.9163 19.1045 13.7543 20 11.5 20C9.24566 20 7.08365 19.1045 5.48959 17.5104C3.89553 15.9163 3 13.7543 3 11.5ZM11.5 2C10.2524 2 9.0171 2.24572 7.86451 2.72314C6.71191 3.20056 5.66464 3.90033 4.78249 4.78249C3.90033 5.66464 3.20056 6.71191 2.72314 7.86451C2.24572 9.0171 2 10.2524 2 11.5C2 12.7476 2.24572 13.9829 2.72314 15.1355C3.20056 16.2881 3.90033 17.3354 4.78249 18.2175C5.66464 19.0997 6.71191 19.7994 7.86451 20.2769C9.0171 20.7543 10.2524 21 11.5 21C14.0196 21 16.4359 19.9991 18.2175 18.2175C19.9991 16.4359 21 14.0196 21 11.5C21 8.98044 19.9991 6.56408 18.2175 4.78249C16.4359 3.00089 14.0196 2 11.5 2ZM9.854 14.854L16.354 8.354L15.646 7.646L9.5 13.793L7.354 11.646L6.646 12.354L9.146 14.854L9.5 15.207L9.854 14.854Z" fill="#419468"/>
    </svg>
    `),
        heroCheckmarkIconCSS: encodeURIComponent(`<svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none"
    xmlns="http://www.w3.org/2000/svg" 
  >
    <path 
      fill-rule="evenodd" 
      clip-rule="evenodd" 
      d="M3 11.5C3 9.24566 3.89553 7.08365 5.48959 5.48959C7.08365 3.89553 9.24566 3 11.5 3C13.7543 3 15.9163 3.89553 17.5104 5.48959C19.1045 7.08365 20 9.24566 20 11.5C20 13.7543 19.1045 15.9163 17.5104 17.5104C15.9163 19.1045 13.7543 20 11.5 20C9.24566 20 7.08365 19.1045 5.48959 17.5104C3.89553 15.9163 3 13.7543 3 11.5ZM11.5 2C10.2524 2 9.0171 2.24572 7.86451 2.72314C6.71191 3.20056 5.66464 3.90033 4.78249 4.78249C3.90033 5.66464 3.20056 6.71191 2.72314 7.86451C2.24572 9.0171 2 10.2524 2 11.5C2 12.7476 2.24572 13.9829 2.72314 15.1355C3.20056 16.2881 3.90033 17.3354 4.78249 18.2175C5.66464 19.0997 6.71191 19.7994 7.86451 20.2769C9.0171 20.7543 10.2524 21 11.5 21C14.0196 21 16.4359 19.9991 18.2175 18.2175C19.9991 16.4359 21 14.0196 21 11.5C21 8.98044 19.9991 6.56408 18.2175 4.78249C16.4359 3.00089 14.0196 2 11.5 2ZM9.854 14.854L16.354 8.354L15.646 7.646L9.5 13.793L7.354 11.646L6.646 12.354L9.146 14.854L9.5 15.207L9.854 14.854Z" 
      fill="#78FAAE"
    />
  </svg>`),
        bulletIconCSS: encodeURIComponent(`<svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none"
    xmlns="http://www.w3.org/2000/svg" 
  >
    <path 
      fill-rule="evenodd" 
      clip-rule="evenodd" 
      d="M3 11.5C3 9.24566 3.89553 7.08365 5.48959 5.48959C7.08365 3.89553 9.24566 3 11.5 3C13.7543 3 15.9163 3.89553 17.5104 5.48959C19.1045 7.08365 20 9.24566 20 11.5C20 13.7543 19.1045 15.9163 17.5104 17.5104C15.9163 19.1045 13.7543 20 11.5 20C9.24566 20 7.08365 19.1045 5.48959 17.5104C3.89553 15.9163 3 13.7543 3 11.5ZM11.5 2C10.2524 2 9.0171 2.24572 7.86451 2.72314C6.71191 3.20056 5.66464 3.90033 4.78249 4.78249C3.90033 5.66464 3.20056 6.71191 2.72314 7.86451C2.24572 9.0171 2 10.2524 2 11.5C2 12.7476 2.24572 13.9829 2.72314 15.1355C3.20056 16.2881 3.90033 17.3354 4.78249 18.2175C5.66464 19.0997 6.71191 19.7994 7.86451 20.2769C9.0171 20.7543 10.2524 21 11.5 21C14.0196 21 16.4359 19.9991 18.2175 18.2175C19.9991 16.4359 21 14.0196 21 11.5C21 8.98044 19.9991 6.56408 18.2175 4.78249C16.4359 3.00089 14.0196 2 11.5 2ZM9.854 14.854L16.354 8.354L15.646 7.646L9.5 13.793L7.354 11.646L6.646 12.354L9.146 14.854L9.5 15.207L9.854 14.854Z" 
      fill="#78FAAE"
    />
  </svg>`),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.49 20L11.9986 13.52L5.53001 20L4.01001 18.48L10.49 12L4.01001 5.53143L5.53001 4L12.01 10.48L18.49 4L19.9986 5.52L13.5186 12L19.9986 18.48L18.49 20Z", fill: "currentColor" }))),
        helpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M11.5 20C13.7543 20 15.9163 19.1045 17.5104 17.5104C19.1045 15.9163 20 13.7543 20 11.5C20 9.24566 19.1045 7.08365 17.5104 5.48959C15.9163 3.89553 13.7543 3 11.5 3C9.24566 3 7.08365 3.89553 5.48959 5.48959C3.89553 7.08365 3 9.24566 3 11.5C3 13.7543 3.89553 15.9163 5.48959 17.5104C7.08365 19.1045 9.24566 20 11.5 20ZM21 11.5C21 12.7476 20.7543 13.9829 20.2769 15.1355C19.7994 16.2881 19.0997 17.3354 18.2175 18.2175C17.3354 19.0997 16.2881 19.7994 15.1355 20.2769C13.9829 20.7543 12.7476 21 11.5 21C10.2524 21 9.0171 20.7543 7.86451 20.2769C6.71191 19.7994 5.66464 19.0997 4.78249 18.2175C3.90033 17.3354 3.20056 16.2881 2.72314 15.1355C2.24573 13.9829 2 12.7476 2 11.5C2 8.98045 3.00089 6.56408 4.78248 4.78249C6.56408 3.00089 8.98044 2 11.5 2C14.0196 2 16.4359 3.00089 18.2175 4.78249C19.9991 6.56408 21 8.98044 21 11.5ZM12 9L12 16L11 16L11 9L12 9ZM12 8L12 7L11 7L11 8L12 8Z", fill: "#419468" }))),
    },
    palette: {
        primary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        secondary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        error: {
            main: colorCard.error,
        },
        backgroundSecondary: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        background: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        text: {
            primary: colorCard.bodyText,
            secondary: colorCard.subhead,
            hint: colorCard.info,
        },
        divider: colorCard.dividerLine,
    },
    typography: {
        fontFamily: GetFontFamily("Skoda-Next-Regular"),
        color: colorCard?.primary,
        root: {
            fontFamily: GetFontFamily("Skoda-Next-Regular"),
        },
        h1: {
            fontFamily: GetFontFamily("Skoda-Next-Bold"),
            fontSize: "2.25rem",
            lineHeight: "2.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "2.5rem",
                lineHeight: "3rem",
            },
        },
        h2: {
            fontFamily: GetFontFamily("Skoda-Next-Bold"),
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "2rem",
                lineHeight: "2.5rem",
            },
        },
        h3: {
            fontFamily: GetFontFamily("Skoda-Next-Bold"),
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },
        h4: {
            fontFamily: GetFontFamily("Skoda-Next-Bold"),
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontSize: "1.125rem",
            lineHeight: "1.625rem",
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        subhead: {
            fontFamily: GetFontFamily("Skoda-Next-Regular"),
            color: colorCard?.subhead,
            fontSize: "1.25rem",
        },
        // helper text
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontFamily: GetFontFamily("Skoda-Next-Bold"),
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontFamily: GetFontFamily("Skoda-Next-Regular"),
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        body2: {
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        smallText: {
            fontSize: "0.875rem",
            lineHeight: "1.125rem",
            color: colorCard?.bodyText,
        },
    },
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: GetFontFamily("Skoda-Next-Regular"),
            },
        },
        MuiLink: {
            underlineHover: {
                "a&": {
                    // to increase specificy
                    color: colorCard?.bodyText,
                    fontFamily: GetFontFamily("Skoda-Next-Bold"),
                    "&:hover": {
                        color: colorCard?.tetriaryHover,
                    },
                    "&:active": {
                        color: colorCard?.tetriaryHover,
                    },
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard?.wcag,
                },
            },
        },
        MuiMobileStepper: {
            root: {
                backgroundColor: colorCard?.background,
            },
            dot: {
                width: 16,
                height: 16,
                margin: "0 12px",
                border: `1px solid ${colorCard.primary}`,
                backgroundColor: colorCard?.background,
            },
        },
        MuiCardMedia: {
            root: {
                display: "flex",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "#00000000",
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 24,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: "4px 4px 0px 0px",
                border: "none",
            },
            input: {
                borderRadius: "4px 4px 0px 0px",
                backgroundColor: colorCard.background2,
                borderBottom: `1px solid ${colorCard.inputOutline}`,
                "&::placeholder": {
                    color: colorCard.placeholderText,
                },
            },
            notchedOutline: {
                border: "none",
                background: "transparent",
            },
        },
        MuiInputLabel: {
            root: {
                color: colorCard.bodyText,
            },
            formControl: {
                transform: "none",
                color: colorCard.bodyText,
                lineHeight: 1.75,
            },
            outlined: {
                "&$shrink": {
                    transform: "translate(10px, -20px) scale(1)",
                },
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: colorCard.background2,
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: 2,
            },
        },
        MuiPickersDay: {
            dayDisabled: {
                color: colorCard?.background3,
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: colorCard?.label,
            },
            iconButton: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiTooltip: {
            arrow: {
                color: colorCard?.subhead,
            },
            popper: {
                pointerEvents: "auto",
            },
        },
        MuiAccordionDetails: {
            root: {
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    paddingTop: 16,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fill: colorCard?.primary,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 24,
                "text-transform": "none",
            },
            outlined: {
                color: colorCard?.background,
                borderColor: colorCard?.background,
                "&:hover": {
                    borderWidth: 2,
                    backgroundColor: colorCard?.tetriaryHover,
                },
            },
            outlinedPrimary: {
                color: colorCard?.background,
                backgroundColor: colorCard?.wcag,
                "&:hover": {
                    backgroundColor: colorCard?.tetriaryHover,
                },
            },
            containedPrimary: {
                color: colorCard?.headlines,
                backgroundColor: colorCard?.secondary,
                "&:hover": {
                    backgroundColor: colorCard?.primaryHover,
                    "@media (hover: none)": {
                        backgroundColor: colorCard?.primaryHover,
                    },
                },
            },
            contained: {
                boxShadow: "none",
                "&:hover": {
                    boxShadow: "none",
                },
                "&:active": {
                    boxShadow: "none",
                },
                "&$focusVisible": {
                    boxShadow: "none",
                },
                "a&": {
                    "&:hover": {
                        color: colorCard?.headlines,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    "&:active": {
                        color: colorCard?.headlines,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    color: colorCard?.headlines,
                    backgroundColor: colorCard?.secondary,
                    textDecoration: "none",
                },
            },
        },
    },
};
export default createTheme(skoda);
