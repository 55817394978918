import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { BrandsEnum } from "../enums";
import { GetFontFamily } from "./common/Helpers";
// create default theme to get breakpoints
const theme = createTheme();
const colorCard = {
    primary: "#102B4E",
    secondary: "#102B4E",
    tertiary: "#102B4E",
    background: "#FFFFFF",
    cardBackground: "#FFFFFF",
    tableBackground: "#F5F5F5",
    background2: "#F5F5F5",
    accordionClosed: "#D6D6D6",
    tableOutline: "#ADADAD",
    dividerLine: "#D6D6D6",
    secondaryHover: "#464748",
    background3: "#D8D8D8",
    wcagOutline: "#858585",
    inputOutline: "#858585",
    placeholderText: "#858585",
    wcag: "#5C5C5C",
    gdprText: "#5C5C5C",
    helperText: "#5C5C5C",
    primaryHover: "#375F9F",
    tetriaryHover: "#464748",
    subhead: "#333333",
    bodyText: "#333333",
    headlines: "#141414",
    label: "#141414",
    inputText: "#141414",
    success: "#17582E",
    successBackground: "#DAE4DD",
    info: "#1E369B",
    infoBackground: "#DBDFEF",
    warning: "#FFE668",
    warningBackground: "#FFFBE7",
    error: "#FF0012",
    errorBackground: "#FFD6D9",
};
const fontStandart = GetFontFamily("Ford Antenna");
// More properties here: https://material-ui.com/customization/default-theme/
export const ford = {
    themeName: "ford",
    colorCard: colorCard,
    brand: BrandsEnum.Ford,
    shape: {
        borderRadius: 24,
    },
    customStyling: {
        global: {
            secondaryButton: {
                fontFamily: fontStandart,
                boxSizing: "border-box",
                appearance: "none",
                userSelect: "none",
                transition: "all 0.35s ease-out",
                display: "inline-flex",
                cursor: "pointer",
                padding: "0 24px",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
                borderRadius: 24,
                border: `1px solid ${colorCard?.primary || theme.palette?.primary?.main}`,
                background: colorCard?.background || theme.palette?.primary?.contrastText,
                color: colorCard?.primary || theme.palette?.primary?.main,
                minHeight: "48px",
                minWidth: "200px",
                lineHeight: "24px",
                textDecoration: "none",
                [theme.breakpoints.down("xs")]: {
                    width: "100%",
                },
                "&:hover, &:focus": {
                    border: `1px solid ${colorCard?.primaryHover || theme.palette?.primary?.dark}`,
                    color: colorCard?.background ||
                        theme.palette?.primary?.contrastText,
                    backgroundColor: colorCard?.primaryHover || theme.palette?.primary?.dark,
                    textDecoration: "none",
                },
            },
            tetriaryButton: {
                fontWeight: 700
            },
            primaryButton: {
                fontFamily: fontStandart,
                borderRadius: 24,
                boxSizing: "border-box",
                appearance: "none",
                userSelect: "none",
                transition: "all 0.35s ease-out",
                display: "inline-flex",
                cursor: "pointer",
                padding: "0 24px",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
                marginTop: 8,
                border: `1px solid ${colorCard?.primary || theme.palette?.primary?.main}`,
                background: colorCard?.primary || theme.palette?.primary?.main,
                color: colorCard?.background || theme.palette?.primary?.contrastText,
                minHeight: "48px",
                minWidth: "200px",
                lineHeight: "24px",
                textDecoration: "none",
                [theme.breakpoints.down("xs")]: {
                    width: "100%",
                },
                "&:hover, &:focus": {
                    backgroundColor: colorCard?.primaryHover || theme.palette?.primary?.dark,
                    border: `1px solid ${colorCard?.primaryHover || theme.palette?.primary?.dark}`,
                    textDecoration: "none",
                    color: colorCard?.background ||
                        theme.palette?.primary?.contrastText,
                },
                "& a": {
                    textDecoration: "none",
                    color: colorCard.background,
                    "&:hover": {
                        textDecoration: "none",
                        color: colorCard.background,
                    },
                },
            },
            textArea: {
                a: {
                    color: colorCard.bodyText,
                    "&:hover": {
                        color: colorCard.bodyText,
                    },
                    "&:active": {
                        color: colorCard.bodyText,
                    },
                },
                legalText: {
                    color: colorCard.gdprText,
                },
            },
        },
        MegaMenu: {
            rootLink: {
                color: colorCard.bodyText,
            },
        },
        LinkWithArrow: {
            linkWithIcon: {
                textDecoration: "none",
                fontWeight: 700
            },
            icon: {
                display: "block",
            },
        },
        LinkWithIcon: {
            link: {
                textDecoration: "none",
            },
        },
        AccordionBlock: {
            accordionBox: {
                borderTop: `1px solid ${colorCard.accordionClosed}`,
                borderBottom: `1px solid ${colorCard.accordionClosed}`,
                backgroundColor: colorCard.accordionClosed
            },
            accordionHeader: {
                backgroundColor: colorCard.background2
            }
        },
        AccordionBlockContainer: {
            root: {
                border: `1px solid ${colorCard.dividerLine}`,
            },
        },
        ArticleTeaser: {
            root: {
                border: `1px solid ${colorCard?.dividerLine}`,
            },
        },
        ContactUsAreaTabHead: {
            headerText: {
                fontWeight: 700,
            },
            activeHeaderText: {
                fontWeight: 700,
            },
            expandButtonContainer: {
                paddingRight: 16,
            }
        },
        NavigationItems: {
            activeItem: {
                color: colorCard.subhead,
            },
            wrapper: {
                backgroundColor: colorCard.background
            }
        },
        WebshopEntryBlock: {
            gdprLinkArea: {
                "& .text-area p a": {
                    fontWeight: 500,
                },
            }
        },
        CardInformationBlock: {
            headerwrapper: {
                backgroundColor: colorCard.tertiary,
            },
            cardLinkInversed: {
                "& .MuiSvgIcon-root": {
                    fill: colorCard.background,
                },
            },
            cardLink: {
                "& a": {
                    color: colorCard.bodyText,
                },
            },
            teaserGridInversed: {
                backgroundColor: colorCard.tertiary,
            },
            darkModeHover: colorCard.accordionClosed,
        },
        CustomizedTextBox: {
            textBoxInput: {
                "&::placeholder": {
                    color: colorCard?.placeholderText,
                    opacity: 1,
                },
            },
        },
        factAndIcon: {
            header: {
                color: colorCard?.bodyText,
            },
        },
        BreadCrumbs: {
            wrapper: {
                backgroundColor: colorCard.background2,
            },
            innerWrapper: {
                backgroundColor: colorCard.background2,
            },
        },
        BraviContentPage: {
            heroWithHeadlineWrapper: {
                "& h4": {
                    color: colorCard.background,
                },
                "& .text-area": {
                    color: colorCard.background,
                },
                "& h2": {
                    color: colorCard.background,
                },
                "& h1": {
                    color: colorCard.background,
                },
                "& h3": {
                    color: colorCard.background,
                },
                "& p": {
                    color: colorCard.background,
                },
                "& span": {
                    color: colorCard.background,
                },
                "& label": {
                    color: colorCard.background,
                },
                "& a.button-secondary": {
                    border: `1px solid ${colorCard.background}`,
                    "&:hover": {
                        border: `2px solid ${colorCard.background}`,
                    },
                },
            },
        },
        MOStartPage: {
            heroWithHeadline: {
                "& h4": {
                    color: colorCard.background,
                },
                "& .text-area": {
                    color: colorCard.background,
                },
                "& h2": {
                    color: colorCard.background,
                },
                "& h1": {
                    color: colorCard.background,
                },
                "& h3": {
                    color: colorCard.background,
                },
                "& p": {
                    color: colorCard.background,
                },
                "& span": {
                    color: colorCard.background,
                },
                "& label": {
                    color: colorCard.background,
                },
                "& button": {
                    "& span": {
                        color: colorCard.bodyText,
                    },
                },
                "& a.button-secondary": {
                    border: `1px solid ${colorCard.background}`,
                    "&:hover": {
                        border: `2px solid ${colorCard.background}`,
                    },
                },
            },
        },
        Footer: {
            aboutFooterText: {
                fontSize: "0.875rem",
                color: colorCard.primary,
            },
            wrapper: {
                borderTop: `1px solid ${colorCard?.inputOutline}`,
                borderBottom: `1px solid ${colorCard?.inputOutline}`,
                backgroundColor: colorCard.primary,
                "& .MuiTypography-h4": {
                    color: colorCard.background,
                },
                "& .MuiLink-root": {
                    color: colorCard.background3,
                    fontWeight: 500,
                    "&:hover": {
                        color: colorCard.background,
                    },
                },
                "& .MuiSvgIcon-root": {
                    display: "none",
                },
                "& hr": {
                    background: colorCard.wcag
                }
            },
            bottomLinkWrapper: {
                fontWeight: 500,
                "& .MuiSvgIcon-root": {
                    display: "none",
                },
            }
        },
    },
    navigation: {
        mobileCollapseBackgroundColor: colorCard.background,
        divider: colorCard.dividerLine,
    },
    breadcrumbs: {
        activeColor: colorCard.label,
        color: colorCard.label,
        iconColor: colorCard.primary,
    },
    USPContainerBlock: {
        backgroundColor: colorCard.tertiary,
        headerColor: colorCard.secondary,
        textColor: colorCard.secondary,
    },
    header: {
        uspBannerBackground: colorCard.primary,
        uspBannerTextColor: colorCard.background,
        navigationLinkColor: colorCard.bodyText,
    },
    heroWithHeadline: {
        backgroundColor: colorCard.tertiary,
        textColor: colorCard.background,
        body: {
            button: {
                primary: {
                    color: colorCard.bodyText,
                    backgroundColor: colorCard.background,
                    borderColor: colorCard.secondary,
                },
                secondary: {
                    color: colorCard.background,
                    borderColor: colorCard.background,
                    backgroundColor: "transparent",
                },
            },
        },
    },
    webshopEntry: {
        backgroundColor: colorCard.background3,
        inputFieldColor: colorCard.background,
    },
    contactUs: {
        activeBackgroundColor: colorCard.background3,
        backgroundColor: colorCard.background2,
        activeHeaderColor: colorCard.label,
    },
    tooltip: {
        backgroundColor: colorCard.label,
    },
    cardInformationBlock: {
        backgroundColor: colorCard.tertiary,
    },
    link: {
        showArrow: false,
    },
    teaser: {
        icon: {
            textIndent: "0px",
            paddingLeft: "30px",
            backgroundPosition: "0 0px"
        }
    },
    searchBoxContainer: {
        close: {
            placeholderColor: colorCard.bodyText,
        },
        open: {
            borderWidth: "1px",
            borderColor: colorCard.inputOutline,
            borderStyle: "solid",
            inputColor: colorCard.headlines,
            placeholderColor: "#949494",
        },
    },
    searchResultsPage: {
        headerBackgroundColor: colorCard.dividerLine,
        headerTextColor: colorCard.headlines,
        subheadTextColor: colorCard.helperText,
        resultTitleColor: colorCard.headlines,
        resultDescriptionColor: colorCard.bodyText,
        resultLinkColor: colorCard.bodyText,
        resultLinkHoverColor: colorCard.bodyText,
        resultLinkActiveColor: colorCard.bodyText,
    },
    icons: {
        searchIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Search" },
                React.createElement("path", { id: "Vector", d: "M14.248 14.8291L21.4205 22M16.8906 9.44531C16.8906 5.3336 13.557 2 9.44531 2C5.3336 2 2 5.3336 2 9.44531C2 13.557 5.3336 16.8906 9.44531 16.8906C13.557 16.8906 16.8906 13.557 16.8906 9.44531Z", stroke: "#102B4E", "stroke-miterlimit": "10" })))),
        expandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4 8L12 16.0018L20 8", stroke: "#102B4E", "stroke-miterlimit": "10" }))),
        expandIconRotation: "180",
        expandIconNavigation: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4 8L12 16.0018L20 8", stroke: "#102B4E", "stroke-miterlimit": "10" }))),
        expandIconRotationNavigation: "180",
        contactUsExpandIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4 8L12 16.0018L20 8", stroke: "#102B4E", "stroke-miterlimit": "10" }))),
        checkmarkIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M6.35547 10.1949L11.2901 15.1279L21.9999 4.41974", stroke: "#102B4E", "stroke-miterlimit": "10" }),
            React.createElement("path", { d: "M19.1028 4.96822C18.8882 4.75206 18.664 4.54396 18.4317 4.34715C16.6927 2.8824 14.4472 2 11.9952 2C6.47493 2 2 6.47491 2 11.9935C2 17.5122 6.47493 21.9871 11.9952 21.9871C17.5154 21.9871 21.9903 17.5122 21.9903 11.9935C21.9903 10.9627 21.8339 9.96741 21.5435 9.03178C21.3967 8.56074 21.2176 8.1026 21.0063 7.66382", stroke: "#102B4E", "stroke-miterlimit": "10" }))),
        closeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4.65574 20L4 19.3443L11.3443 12L4 4.65574L4.65574 4L12 11.3443L19.3443 4L20 4.65574L12.6557 12L20 19.3443L19.3443 20L12 12.6557L4.65574 20Z", fill: "#102B4E" }))),
        secondaryCloseIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { id: "Close" },
                React.createElement("path", { id: "Vector", d: "M4.65574 20L4 19.3443L11.3443 12L4 4.65574L4.65574 4L12 11.3443L19.3443 4L20 4.65574L12.6557 12L20 19.3443L19.3443 20L12 12.6557L4.65574 20Z", fill: "#102B4E" })))),
        tooltipCloseIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4.65574 20L4 19.3443L11.3443 12L4 4.65574L4.65574 4L12 11.3443L19.3443 4L20 4.65574L12.6557 12L20 19.3443L19.3443 20L12 12.6557L4.65574 20Z", fill: "white" }))),
        checkmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.35547 10.1949L11.2901 15.1279L21.9999 4.41974" stroke="#102B4E" stroke-miterlimit="10"/>
      <path d="M19.1028 4.96822C18.8882 4.75206 18.664 4.54396 18.4317 4.34715C16.6927 2.8824 14.4472 2 11.9952 2C6.47493 2 2 6.47491 2 11.9935C2 17.5122 6.47493 21.9871 11.9952 21.9871C17.5154 21.9871 21.9903 17.5122 21.9903 11.9935C21.9903 10.9627 21.8339 9.96741 21.5435 9.03178C21.3967 8.56074 21.2176 8.1026 21.0063 7.66382" stroke="#102B4E" stroke-miterlimit="10"/>
      </svg>`),
        heroCheckmarkIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.35547 10.1949L11.2901 15.1279L21.9999 4.41974" stroke="#FFFFFF" stroke-miterlimit="10"/>
      <path d="M19.1028 4.96822C18.8882 4.75206 18.664 4.54396 18.4317 4.34715C16.6927 2.8824 14.4472 2 11.9952 2C6.47493 2 2 6.47491 2 11.9935C2 17.5122 6.47493 21.9871 11.9952 21.9871C17.5154 21.9871 21.9903 17.5122 21.9903 11.9935C21.9903 10.9627 21.8339 9.96741 21.5435 9.03178C21.3967 8.56074 21.2176 8.1026 21.0063 7.66382" stroke="#FFFFFF" stroke-miterlimit="10"/>
      </svg>`),
        bulletIconCSS: encodeURIComponent(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.35547 10.1949L11.2901 15.1279L21.9999 4.41974" stroke="#102B4E" stroke-miterlimit="10"/>
      <path d="M19.1028 4.96822C18.8882 4.75206 18.664 4.54396 18.4317 4.34715C16.6927 2.8824 14.4472 2 11.9952 2C6.47493 2 2 6.47491 2 11.9935C2 17.5122 6.47493 21.9871 11.9952 21.9871C17.5154 21.9871 21.9903 17.5122 21.9903 11.9935C21.9903 10.9627 21.8339 9.96741 21.5435 9.03178C21.3967 8.56074 21.2176 8.1026 21.0063 7.66382" stroke="#102B4E" stroke-miterlimit="10"/>
      </svg>`),
        excludeIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M4.65574 20L4 19.3443L11.3443 12L4 4.65574L4.65574 4L12 11.3443L19.3443 4L20 4.65574L12.6557 12L20 19.3443L19.3443 20L12 12.6557L4.65574 20Z", fill: "#102B4E" }))),
        helpIcon: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M11.487 17H12.5304V10.8869H11.487V17ZM11.997 9.33042C12.1918 9.33042 12.3511 9.26946 12.475 9.14754C12.5989 9.02561 12.6609 8.86727 12.6609 8.67253C12.6609 8.4778 12.5999 8.31847 12.478 8.19456C12.356 8.07064 12.1977 8.00869 12.003 8.00869C11.8082 8.00869 11.6489 8.06966 11.525 8.19159C11.4011 8.31351 11.3391 8.47184 11.3391 8.66657C11.3391 8.86131 11.4001 9.02063 11.522 9.14455C11.644 9.26846 11.8023 9.33042 11.997 9.33042ZM12.0052 22C10.6223 22 9.3217 21.7373 8.10328 21.2119C6.88486 20.6865 5.825 19.9735 4.9237 19.0729C4.02241 18.1722 3.3098 17.1144 2.78588 15.8995C2.26196 14.6846 2 13.3865 2 12.0052C2 10.6223 2.26269 9.3217 2.78808 8.10328C3.31347 6.88486 4.02649 5.825 4.92715 4.9237C5.82782 4.0224 6.88561 3.3098 8.10051 2.78588C9.3154 2.26196 10.6135 2 11.9948 2C13.3777 2 14.6783 2.26269 15.8967 2.78808C17.1151 3.31347 18.175 4.02649 19.0763 4.92715C19.9776 5.82782 20.6902 6.88561 21.2141 8.10051C21.738 9.3154 22 10.6135 22 11.9948C22 13.3777 21.7373 14.6783 21.2119 15.8967C20.6865 17.1151 19.9735 18.175 19.0729 19.0763C18.1722 19.9776 17.1144 20.6902 15.8995 21.2141C14.6846 21.738 13.3865 22 12.0052 22ZM11.9993 20.9565C14.4918 20.9565 16.6078 20.087 18.3473 18.348C20.0868 16.609 20.9565 14.4932 20.9565 12.0007C20.9565 9.50821 20.087 7.39221 18.348 5.65272C16.609 3.91322 14.4932 3.04348 12.0007 3.04348C9.50821 3.04348 7.39221 3.91298 5.65272 5.65198C3.91322 7.39101 3.04348 9.50678 3.04348 11.9993C3.04348 14.4918 3.91298 16.6078 5.65198 18.3473C7.39101 20.0868 9.50678 20.9565 11.9993 20.9565Z", fill: "#102B4E" }))),
    },
    palette: {
        primary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        secondary: {
            main: colorCard.primary,
            dark: colorCard.bodyText,
            contrastText: colorCard.background,
        },
        error: {
            main: colorCard.error,
        },
        backgroundSecondary: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        background: {
            default: colorCard.background,
            paper: colorCard.background2,
        },
        text: {
            primary: colorCard.bodyText,
            secondary: colorCard.subhead,
            hint: colorCard.info,
        },
        divider: colorCard.dividerLine,
    },
    typography: {
        fontFamily: fontStandart,
        color: colorCard?.primary,
        root: {
            fontFamily: fontStandart,
        },
        h1: {
            fontFamily: fontStandart,
            fontSize: "2.25rem",
            lineHeight: "2.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontWeight: 500,
            [theme.breakpoints.up("md")]: {
                fontSize: "2.5rem",
                lineHeight: "3rem",
            },
        },
        h2: {
            fontFamily: fontStandart,
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontWeight: 500,
            [theme.breakpoints.up("md")]: {
                fontSize: "2rem",
                lineHeight: "2.5rem",
            },
        },
        h3: {
            fontFamily: fontStandart,
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontWeight: 500,
            [theme.breakpoints.up("md")]: {
                fontSize: "1.5rem",
                lineHeight: "2rem",
            },
        },
        h4: {
            fontFamily: fontStandart,
            color: colorCard?.headlines,
            "word-break": "break-word",
            fontSize: "1.125rem",
            lineHeight: "1.625rem",
            fontWeight: 500,
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                lineHeight: "1.75rem",
            },
        },
        subhead: {
            fontFamily: fontStandart,
            color: colorCard?.subhead,
            fontSize: "1.25rem",
        },
        // helper text
        caption: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        button: {
            fontFamily: fontStandart,
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.5rem",
            minHeight: 48,
        },
        subtitle2: {
            fontFamily: fontStandart,
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        body2: {
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: colorCard?.bodyText,
        },
        smallText: {
            fontSize: "0.875rem",
            lineHeight: "1.125rem",
            color: colorCard?.bodyText,
        },
    },
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: fontStandart,
            },
        },
        MuiLink: {
            underlineHover: {
                "a&": {
                    // to increase specificy
                    color: colorCard?.bodyText,
                    fontFamily: fontStandart,
                    "&:hover": {
                        color: colorCard?.tetriaryHover,
                    },
                    "&:active": {
                        color: colorCard?.tetriaryHover,
                    },
                },
                "& .MuiSvgIcon-root": {
                    fill: colorCard?.primary,
                },
            },
        },
        MuiMobileStepper: {
            root: {
                backgroundColor: colorCard?.tableOutline,
            },
            dot: {
                width: 16,
                height: 16,
                margin: "0 12px",
                border: `1px solid ${colorCard.tableOutline}`,
                backgroundColor: colorCard?.tableOutline,
            },
        },
        MuiCardMedia: {
            root: {
                display: "flex",
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "#00000000",
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: 24,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: "4px 4px 0px 0px",
                border: "none",
            },
            input: {
                borderRadius: "4px",
                border: `1px solid ${colorCard.inputOutline}`,
                "&::placeholder": {
                    color: colorCard.placeholderText,
                },
            },
            notchedOutline: {
                border: "none",
                background: "transparent",
            },
        },
        MuiInputLabel: {
            root: {
                color: colorCard.bodyText,
            },
            formControl: {
                transform: "none",
                color: colorCard.bodyText,
                lineHeight: 1.75,
            },
            outlined: {
                "&$shrink": {
                    transform: "translate(10px, -20px) scale(1)",
                },
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: colorCard.background2,
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: 2,
            },
        },
        MuiPickersDay: {
            dayDisabled: {
                color: colorCard?.background3,
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: colorCard?.label,
            },
            iconButton: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: colorCard?.background,
            },
        },
        MuiTooltip: {
            arrow: {
                color: colorCard?.subhead,
            },
            popper: {
                pointerEvents: "auto",
            },
        },
        MuiAccordionDetails: {
            root: {
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    paddingTop: 16,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fill: colorCard?.primary,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 24,
                "text-transform": "none",
                "text-align": "center",
            },
            outlined: {
                color: colorCard?.primary,
                borderColor: colorCard?.inputOutline,
                backgroundColor: colorCard.background,
                "&:hover": {
                    borderWidth: 2,
                    backgroundColor: colorCard?.primaryHover,
                },
            },
            outlinedPrimary: {
                color: colorCard?.primary,
                backgroundColor: colorCard?.background,
                "&:hover": {
                    color: colorCard?.background,
                    backgroundColor: colorCard?.primaryHover,
                },
            },
            containedPrimary: {
                color: colorCard?.background,
                backgroundColor: colorCard?.primary,
                "&:hover": {
                    backgroundColor: colorCard?.primaryHover,
                    "@media (hover: none)": {
                        backgroundColor: colorCard?.primaryHover,
                    },
                },
            },
            contained: {
                boxShadow: "none",
                "&:hover": {
                    boxShadow: "none",
                },
                "&:active": {
                    boxShadow: "none",
                },
                "&$focusVisible": {
                    boxShadow: "none",
                },
                "a&": {
                    "&:hover": {
                        color: colorCard?.background,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    "&:active": {
                        color: colorCard?.headlines,
                        backgroundColor: colorCard?.primaryHover,
                    },
                    color: colorCard?.background,
                    backgroundColor: colorCard?.primary,
                    textDecoration: "none",
                },
            },
        },
    },
};
export default createTheme(ford);
